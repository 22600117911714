import mapboxgl, { Expression } from "mapbox-gl";

import { FuelMapping, MapLevel } from "constants/enums";
import { IAppSlice, IMapState } from "modules/app/slice";

import { fuelCategoryPalette } from "utils/ColorPalette";
import { getDataAvailability, isAliasForBA } from "utils/DataAvailability";

import baMapData from 'data/geojson/ba_simple_meta.json';
import largeBaMapData from 'data/geojson/custom_ba_simple_meta.json';
import isoMapData from 'data/geojson/iso_simple_meta.json';
import misoCountyData from 'data/geojson/miso_counties_simple_meta.json';
import misoLbaData from 'data/geojson/miso_lbas_simple_meta.json';
import misoLrzData from 'data/geojson/miso_lrz_simple_meta.json';
import misoMapData from 'data/geojson/miso_simple_meta.json';
import misoStateData from 'data/geojson/miso_states_simple_meta.json';
import misoSubregionData from 'data/geojson/miso_subregions_simple_meta.json';
import stateMapData from 'data/geojson/us_states_lower48_meta.json';

import { MISO_COUNTIES, MISO_LBAS, MISO_STATES } from "constants/constants";
import baOverlapData from 'data/geojson/ba_overlap_meta.json';
import largeBaOverlapData from 'data/geojson/custom_ba_overlap_meta.json';
import isoOverlapData from 'data/geojson/iso_overlap_meta.json';


export function loadSvgImage(svg: any, onload: any) {
  let img = new Image(42, 42);
  img.onload = () => onload(img);
  const blob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' })
  const dataUrl = URL.createObjectURL(blob);
  img.src = dataUrl;
}


// Hacky way of create cross-hatch patterns. We generate a hatch texture for
// every possible pair of colors, and choose from them later. The `callback`
// function is called once all patterns are loaded.
export const generateOverlapColors = (map: mapboxgl.Map, callback: () => void) => {
  const colorMap = [
    "#9e0142", "#d53e4f", "#f46d43", "#fdae61", "#fee08b",
    "#e6f598", "#abdda4", "#66c2a5", "#3284bd", "#5e4fa2"
  ];

  const svgTemplate = `
    <svg width="84" height="84" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="transformedPattern"
            x="0" y="0" width="2" height="20"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)">
          <rect width="2" height="20" x="0" y="0" style="fill: #0000ff; opacity: 0.15" />
          <circle cx="1" cy="1" r="8" style="stroke: none; fill: #000000; opacity: 0.15" />
        </pattern>
      </defs>
      <rect x="0" y="0" width="84" height="84" style="stroke: none; fill: url(#transformedPattern);" />
    </svg>`

  const promises = [];
  for (let i = 0; i < colorMap.length; ++i) {
    for (let j = 0; j < colorMap.length; ++j) {
      promises.push(
        new Promise<void>((resolve, _) => {
          const coloredSvg = svgTemplate.replace('#000000', colorMap[j]).replace('#0000ff', colorMap[i]);
          loadSvgImage(coloredSvg, (img: any) => {
            map.addImage(`pattern-${i}-${j}`, img);
            resolve();
          });
        })
      );
    }
  }

  Promise.all(promises)
    .then(callback)
    .catch(console.error);
}


export const queryParamsAreComplete = (appState: IAppSlice, mapState: IMapState) => {
  // The user might be zoomed into MISO with no subregions selected, but implicitly with MISO selected.
  const didSelectRegions = (mapState.mapSelection.size > 0 ||
                           mapState.mapLevel === MapLevel.LRZs);
  const didSelectDates = appState.queryStartDate != null && appState.queryEndDate != null;
  const didSelectPollutant = appState.pollutant;
  return didSelectRegions && didSelectDates &&
         didSelectPollutant;
}


export const makeFuelColorMapping = (appState: IAppSlice) => {
  let fuelCategoryPaletteMapping = [
    'coal',
    fuelCategoryPalette['coal'],
    'natural_gas',
    fuelCategoryPalette['natural_gas'],
    'petroleum',
    fuelCategoryPalette['petroleum'],
    'solar',
    fuelCategoryPalette['solar'],
    'wind',
    fuelCategoryPalette['wind'],
    'hydro',
    fuelCategoryPalette['hydro'],
    'storage',
    fuelCategoryPalette['storage'],
    'nuclear',
    fuelCategoryPalette['nuclear'],
  ];

  if (appState.fuelMapping === FuelMapping.Expanded) {
    fuelCategoryPaletteMapping = fuelCategoryPaletteMapping.concat([
      'geothermal',
      fuelCategoryPalette['geothermal'],
      'biomass',
      fuelCategoryPalette['biomass'],
      'wood',
      fuelCategoryPalette['wood'],
      'waste',
      fuelCategoryPalette['waste'],
    ]);
  }
  return fuelCategoryPaletteMapping;
}


export const makeCircleRadiusExpression = () => {
  return [
    'interpolate',
    ['linear'],
    ['zoom'],
    3, 1,
    12, [
          'interpolate',
          ['linear'],
          ['get', 'capacityMw'],
          50, 8,
          500, 16,
          5000, 32,
          50000, 64,
        ]
  ] as Expression;
}


export const makeModelBuiltIconSizeExpression = () => {
  const baseIconSize = 10;

  return [
    'interpolate',
    ['exponential', 2],
    ['zoom'],
    3, 0.2,
    12, [
        'interpolate',
        ['linear'],
        ['get', 'capacityMw'],
        50, baseIconSize,
        500, 2*baseIconSize,
        5000, 4*baseIconSize,
        50000, 8*baseIconSize,
    ]
  ] as Expression;
}


export const shouldShowMapSubRegions = (appState: IAppSlice, mapState: IMapState) => {
  // Subregions are shown when (1) the current mapLevel is LRZs, or (2) the
  // mapLevel is BA-like and only MISO is selected, and the user is zoomed.
  const zoomedIntoMISO = isAliasForBA(mapState.mapLevel) &&
                         mapState.mapSelection.has('MISO') &&
                         mapState.mapSelection.size === 1 &&
                         mapState.userEnteredSelection;
  const dataAvailalability = getDataAvailability(appState, mapState);
  const canShowLrzData = dataAvailalability.mapLevels.has(MapLevel.LRZs);
  return (mapState.mapLevel === MapLevel.LRZs || zoomedIntoMISO) && canShowLrzData;
}


export const filterMapData = (mapData: any, appState: IAppSlice, mapState: IMapState) => {
  // NOTE(milo): Really hacky way of handling LRZs when we can't actually show LRZs.
  const showSubRegions = shouldShowMapSubRegions(appState, mapState);
  const showingDifferentMapLayerThanSelection = showSubRegions && mapState.mapLevel !== MapLevel.LRZs;

  let filteredMapData = mapData;
  if (mapState.userEnteredSelection && mapState.mapSelection.size > 0 && !showingDifferentMapLayerThanSelection) {
    filteredMapData = {
      type: 'FeatureCollection',
      features: mapData.features.filter((f: any) => mapState.mapSelection.has(f.properties.apiRegionCode))
    };
  }

  // Add a 'selected' tag to the selected features to use for visualization.
  filteredMapData.features = filteredMapData.features.map((f: any) => {
    let f2 = f;
    f2.properties['selected'] = mapState.mapSelection.has(f.properties.apiRegionCode);
    return f2;
  });

  return filteredMapData;
}


// This function decides which GeoJSON layer is shown.
export const getMapData = (appState: IAppSlice, mapState: IMapState, showOnlyMISO=false) => {
  const showSubRegions = shouldShowMapSubRegions(appState, mapState);

  if (showSubRegions) {
    return filterMapData(misoLrzData, appState, mapState);
  }

  const filterForMISO = (mapData: typeof isoMapData): typeof isoMapData => {
    return {
      ...mapData,
      features: mapData.features.filter(d => !showOnlyMISO || d.properties.name === 'MISO')
    };
  };
  return filterMapData({
    [MapLevel.BAs]: filterForMISO((baMapData as typeof isoMapData)),
    [MapLevel.LARGE_BAs]: filterForMISO(largeBaMapData),
    [MapLevel.ISOs]: filterForMISO(isoMapData),
    [MapLevel.MISO]: misoMapData,
    [MapLevel.Subregions]: misoSubregionData,
    [MapLevel.States]: stateMapData,
    [MapLevel.MISO_States]: {...stateMapData, features: stateMapData.features.filter(x => MISO_STATES.includes(x.properties.name))},
    [MapLevel.MISO_Counties]: {...misoCountyData, features: misoCountyData.features.filter(x => MISO_COUNTIES.includes(x.properties.name))},
    [MapLevel.LRZs]: misoLrzData,
    [MapLevel.LBAs]: {...misoLbaData, features: misoLbaData.features.filter(x => MISO_LBAS.includes(x.properties.apiRegionCode))},
  }[mapState.mapLevel], appState, mapState);
}

export const getDoesRegionExistInLevel = (regionName: string, level: MapLevel) => {
  const getName = (data: typeof isoMapData.features[0]) => data.properties.name;

  const dataMapping = {
    [MapLevel.BAs]: baMapData,
    [MapLevel.LARGE_BAs]: largeBaMapData,
    [MapLevel.ISOs]: isoMapData,
    [MapLevel.MISO]: misoMapData,
    [MapLevel.Subregions]: misoSubregionData,
    [MapLevel.States]: stateMapData,
    [MapLevel.MISO_States]: misoStateData,
    [MapLevel.MISO_Counties]: misoCountyData,
    [MapLevel.LRZs]: misoLrzData,
    [MapLevel.LBAs]: misoLbaData
  };

  return (dataMapping[level].features as typeof isoMapData.features).map(getName).some(name => name === regionName);
}


export const getMapOverlapData = (appState: IAppSlice, mapState: IMapState) => {
  const willShowSubRegions = shouldShowMapSubRegions(appState, mapState);
  if (willShowSubRegions || mapState.userEnteredSelection) {
    return {
      features: [],
      type: "FeatureCollection"
    };
  }

  if (mapState.mapLevel === MapLevel.BAs) {
    return baOverlapData;
  } else if (mapState.mapLevel === MapLevel.LARGE_BAs) {
    return largeBaOverlapData;
  } else if (mapState.mapLevel === MapLevel.ISOs) {
    return isoOverlapData;
  }

  return {
    features: [],
    type: "FeatureCollection"
  };
}
