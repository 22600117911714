import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import * as React from 'react';

import { AggType, useGetConsumedDataQuery } from 'api/data';
import ConsumedDataView from 'components/DataView/Consumed';
import Navbar from 'components/GenericNavbar';
import ConsumedJoyrideTour from 'components/JoyrideTour/consumed';
import MapView from 'components/MapView';
import OptionsToolbar from 'components/OptionsToolbar';
import SettingsModal from 'components/SettingsModal';
import * as Constants from 'constants/constants';
import { Duration, EmissionFramework, MapLevel, Units } from 'constants/enums';
import dayjs from 'dayjs';
import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { editMapSelection, IMapState, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { useEffect } from 'react';
import { last } from 'utils/utils';


const mapLevelToAggType: Record<MapLevel, AggType> = {
  [MapLevel.MISO]: "miso",
  [MapLevel.Subregions]: "subregion",
  [MapLevel.MISO_States]: "state",
  [MapLevel.MISO_Counties]: "county",
  [MapLevel.LRZs]: "lrz",
  [MapLevel.LBAs]: "lba",

  // Should be impossible to select these
  [MapLevel.BAs]: "miso",
  [MapLevel.LARGE_BAs]: "miso",
  [MapLevel.ISOs]: "miso",
  [MapLevel.States]: "state",
}

const ConsumedPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(editMapSelection({editMode: "overwrite", mapLevel: MapLevel.MISO, mapSelection: new Set(["MISO"])}));
    dispatch(updateAppState({
      queryStartDate: dayjs().startOf('day'),
      queryEndDate: dayjs().endOf('day'),
      emissionFramework: EmissionFramework.Consumed,
      timeResolution: Duration.Hour
    }));
  }, [dispatch]);

  const [settingsMenuOpen, setSettingsMenuOpen] = React.useState(false);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last<IMapState | undefined>(mapHistory);
  const appState = useAppSelector(currentAppState);
  const start = appState.queryStartDate;
  const end = appState.queryEndDate;
  const dateRangeTooBig = start.diff(end, 'day') > 1;

  let aggIds = Array.from(mapState.mapSelection);
  const {
    data,
    isLoading,
    isFetching,
    error
  } = useGetConsumedDataQuery({
    start: start.tz('EST', true).startOf('day').toDate().toISOString(),
    end: end.tz('EST', true).endOf('day').toDate().toISOString(),
    agg_type: mapLevelToAggType[mapState.mapLevel],
    agg_ids: aggIds.join(","),
    units: appState.units === Units.Imperial ? "us" : "metric"
  }, {skip: !mapState || mapState.mapSelection.size === 0 || dateRangeTooBig});

  return <div className="App">
    <Container maxWidth={false} disableGutters={true}>
      <Navbar />

      <ConsumedJoyrideTour />

      <SettingsModal
        open={settingsMenuOpen}
        setOpen={setSettingsMenuOpen}
      />

      <OptionsToolbar
        toggleSettingsMenu={() => setSettingsMenuOpen(!settingsMenuOpen)}
        datePickerType='start-end'
        minDate={Constants.EARLIEST_CONSUMED_DATA}
        leftMostSelection='regionSource'
        showResolution={false}
        showDocs={true}
      />

      <Grid container>
        <Grid item xs={12} lg={7} order={{ xs: 2, lg: 1 }}>
          <ConsumedDataView data={data} isLoading={isLoading || isFetching} noData={!mapState || mapState.mapSelection.size === 0} error={error}/>
        </Grid>
        <Grid item xs={12} lg={5} order={{ xs: 1, lg: 2 }}>
          <MapView
            allowedMapLevels={[MapLevel.MISO, MapLevel.Subregions, MapLevel.LRZs, MapLevel.LBAs, MapLevel.MISO_States, MapLevel.MISO_Counties]}
            showPlants={false}
            showRegionSelect={mapState.mapLevel !== MapLevel.MISO}
            showZoomButton={false}
          />
        </Grid>
      </Grid>
    </Container>
  </div>
}

export default ConsumedPage;