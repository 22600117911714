import React, { useEffect, useMemo, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

import * as Constants from 'constants/constants';
import { MapLevel } from 'constants/enums';
import { editMapSelection } from 'modules/app/slice';
import { useAppDispatch } from 'modules/store';
import { setUserShouldDoRealtimeTour, } from 'modules/tour/slice';
import './style.css';

const tourCompletionCookieName = 'Singularity.ConsumedEmissionsMap.tourVersion';

const steps: Step[] = [
  // STEP 0
  {
    target: 'body',
    placement: 'center',
    content: (
      <div style={{textAlign: 'left'}}>
        <h1 style={{fontSize: '24px'}}><strong>MISO Consumed Emissions Dashboard</strong></h1>
        <div className="realtime-tour-start--explaination">
          <strong>This dashboard visualizes consumed emissions intensities, totals, and fuel mixes as incurred throughout the MISO territory.</strong>
          <hr/>
          <strong style={{textDecoration: 'underline'}}>Data Availability:</strong>
          <ul>
            <li>Publicly consumable, non-confidential data</li>
            <li>Hourly data beginning October 1, 2024, reported as near real-time as confidentiality conventions allow</li>
            <li>Geographic granularity at several levels: Total MISO footprint, MISO's 3 reliability subregions (North, Central, South), MISO's 10 Local Resource Zones (LRZs), MISO's 43 Local Balancing Authorities (LBAs), 10 states within MISO's territory, and 828 counties within MISO's footprint.</li>
            <li>Emissions for CO<sub>2</sub>e</li>
          </ul>
          <hr/>
          <p>For more information, please refer to any of the following resources:</p>
          <ul>
            <li><a href={Constants.CARBON_FLOW_DOCUMENTATION_URL} target='_blank'>MISO Consumed Emissions Technical Documentation</a></li>
            <li>Singularity's <a href={Constants.DASHBOARD_DOCS_URL} target='_blank'>Open Grid Emissions documentation</a></li>
            <li>For questions, please see <a href='https://help.misoenergy.org/knowledgebase/article/KA-01501/en-us' target='_blank'>MISO's Knowledge Articles</a>, or initiate contact with <a href='https://help.misoenergy.org/' target='_blank'>MISO's Help Center.</a></li>
          </ul>
          <p>Click 'Next' for a quick tour of dashboard features, data selections, and information sources.</p>
        </div>
        <p className="realtime-tour-start--prompt">Click 'Next' for a quick tour.</p>
      </div>
    ),
  },
  // STEP 1
  {
    target: '.tour--region-level-select',
    content: 'In this dropdown menu, you can review the geographic areas available for selection, in descending size order.',
    placement: 'left',
  },
  // STEP 2
  {
    target: '.mapboxgl-ctrl-zoom-in',
    content: 'Click this to zoom into the LRZ you selected.',
  },
  // STEP 3
  {
    target: '.tour--select-map-region',
    content: 'Click on the "x" of the selected region within this menu to clear the currently selected region(s). You can select multiple regions and then clear one or all of them here.',
  },
  // STEP 4
  {
    target: '.realtime-tour-date-picker',
    content: 'Customize the time period by selecting start and end dates. Note that this dashboard will not return data visualizations for periods longer than two weeks or more than 10 regions.',
    placement: 'right',
  },
  // STEP 5
  {
    target: '.realtime-tour-emissions-chart',
    content: 'This chart represents a time series of both the consumed carbon intensity (or consumed emission rate) and total consumed emissions for the region(s) and time period selected, as well as a "non-CFE" carbon intensity rate. Intensities and rates are displayed as lines, and the total emissions are displayed as an area chart. The "non-CFE" (non Carbon-Free Energy) rate, also known as a "fossil fuel only" rate, can be used as a proxy emission rate for residual mix. It assumes that every carbon-free electron has been purchased.'
  },
  // STEP 6
  {
    target: '.realtime-tour-emissions-chart',
    content: 'Try "unselecting" one of the carbon intensity lines in the chart by clicking on it in the legend. This and the next functions will work for the chart below as well.'
  },
  // STEP 7
  {
    target: '.realtime-tour-emissions-chart',
    content: 'Try hovering over one of the data points in the chart.'
  },
  // STEP 8
  {
    target: '.realtime-tour-emissions-chart',
    content: 'Try clicking on the three vertical dots to the right of the chart. You can select from this list of actions to download either the data selected or the chart image in various file formats.'
  },
  // STEP 9
  {
    target: '.realtime-tour-fuelmix-chart',
    content: 'This chart shows the consumed fuel mix used to calculate emission rates and emissions for the data and geography selected. Note that consumed fuel mix is different than generated fuel mix, because consumed power flows allocate energy to points of load rather than to the generating unit the power came from.'
  },
  // STEP 10
  {
    target: '.tour--api-docs',
    content: 'Click here to view API documentation. You will need to create or sign in to an account to see your API key.',
    placement: 'right'
  },
  // STEP 11
  {
    target: '.tour--technical-docs',
    content: 'Click here to read the technical documents informing data outputs on this dashboard. Try clicking on either the Glossary or Report Timing / Estimate Release to see an example of the information in this area.',
    placement: 'right'
  },
  // STEP 12
  {
    target: '.tour--settings-menu',
    content: 'You can access some more advanced settings in this menu. For example, you can choose whether to view the \'Simple\' or \'Expanded\' (more detailed) fuel categories.',
    placement: 'right'
  },
  // STEP 13
  {
    target: '.tour--data-download',
    content: 'You can export the data that\'s currently displayed by clicking here.'
  },
  // STEP 14
  {
    target: '.tour--new-navbar',
    content: 'You can view data for near real-time and future projections by switching pages here.'
  },
  // STEP 15
  {
    target: '.tour--start-over',
    content: (
      <div>
        <h5><strong>Done!</strong></h5>
        <br />
        <p>You can click here to replay the walkthrough at any time.</p>
      </div>
    ),
  },
];


const ConsumedJoyrideTour = () => {
  const dispatch = useAppDispatch();

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [resetTour, setResetTour] = useState(0);

  useEffect(() => {
    // Check if the user already skipped or finished the tour. If so, don't show it to them again.
    const maybeTourVersion = localStorage.getItem(tourCompletionCookieName);
    console.debug('[singularity] Tour cookie:', maybeTourVersion);
    if (maybeTourVersion !== Constants.CURRENT_TOUR_VERSION) {
      console.debug('[singularity] The user hasn\'t done this version of the tour, starting from the beginning.');
      setStepIndex(0);
      setRun(true);
    }

    document.getElementById(Constants.TOUR_BUTTON_ID).onclick = startTour;
  }, []);

  // This restarts the tour from the beginning.
  const startTour = () => {
    console.debug('[singularity] Restarting tour');
    localStorage.setItem(tourCompletionCookieName, 'invalidated');
    setStepIndex(0);
    setRun(true);
    setResetTour(resetTour + 1);
  }

  const handleJoyrideCallback = useMemo(() => (data: CallBackProps) => {
    const { action, index, status, type } = data;

    // If the tour is FINISHED or SKIPPED, set the 'run' state to false so the
    // tour can be started again later.
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      console.debug('[singularity] User finished/skipped tour. Saving a cookie to remember.');
      localStorage.setItem(tourCompletionCookieName, Constants.CURRENT_TOUR_VERSION);
      setStepIndex(0);
      setRun(false);
      // Important! Set the global userShouldDoTour so that another button click will restart.
      dispatch(setUserShouldDoRealtimeTour(false));
      return;
    }

    // Always allow the user to step backwards.
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
      setStepIndex(index - 1);
    } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      if (index === 1) {
        dispatch(editMapSelection({ editMode: 'overwrite', mapLevel: MapLevel.LRZs, mapSelection: new Set(["1"]) }));
      }
      if (index === 3) {
        dispatch(editMapSelection({ editMode: 'overwrite', mapLevel: MapLevel.LRZs, mapSelection: new Set([]) }));
      }
      if (index === 4) {
        dispatch(editMapSelection({ editMode: 'overwrite', mapLevel: MapLevel.MISO, mapSelection: new Set(["MISO"]) }));
      }
      setStepIndex(index + 1);
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      console.error('Target for joyride tour not found!');
      setRun(false);
    }
  }, [dispatch]);

  return (
    <div className="joyride-tour">
      <Joyride
        continuous
        hideBackButton
        hideCloseButton
        run={run}
        callback={handleJoyrideCallback}
        scrollToFirstStep
        showProgress
        spotlightClicks
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#0082ca',
            backgroundColor: '#fff',
          },
        }}
      />
    </div>
  );
}


export default ConsumedJoyrideTour;
