import CloudOffRounded from '@mui/icons-material/CloudOffRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import * as Constants from 'constants/constants';
import dayjs, { Dayjs } from 'dayjs';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { flatten, keyBy, property, snakeCase, sum } from 'lodash';
import React, { useEffect } from 'react';

import { IGetRealtimeDataResponse } from 'api/data';
import { EmissionAdjustment, MapLevel, Units } from 'constants/enums';
import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { IMapState, changeMapLevel, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { fuelCategoryPalette } from 'utils/ColorPalette';
import { arrayMean, arraySum, durationToDuration, getBrowserWidth, last } from 'utils/utils';

import { HelpPopup } from 'components/HelpPopup';
import { getDoesRegionExistInLevel } from 'components/MapView/utils';
import './style.css';
import { createDownloadFunction } from './utils';

const cfeFuels = new Set([
  'geothermalMw',
  'hydroMw',
  'nuclearMw',
  'solarMw',
  'storageMw',
  'windMw',
]);

const regionToTz: Record<string, string> = {
  'ISNE': 'US/Eastern',
  'NYIS': 'US/Eastern',
  'BPAT': 'US/Pacific',
  'CISO': 'US/Pacific',
  'ERCO': 'US/Central',
  'SWPP': 'US/Central',
  'PJM': 'US/Eastern',
  'MISO': 'EST',
}


const makeChartOptions = (
  series: {data: {x: number, y: number}[], zIndex?: number, name: string, color?: string, type?: string, yAxis?: number}[],
  chartType: string,
  title: string = '',
  yAxisTitle: string = '',
  extraPlotOptions: any = {},
  secondaryYAxisTitle: string = '',
  region: string = '',
  yAxisFormatter?: ({value}: {value: number}) => string
) => {
  const startOfToday = new Date();
  startOfToday.setUTCHours(0, 0, 0, 0);
  const endOfToday = dayjs().tz(regionToTz[region], true).endOf('day').toDate();
  const minX = Math.min(...flatten(series.map(({data}) => data.map(({x}) => x))));
  const numAfterTodayStart = flatten(series.map(d => d.data.filter(d1 => d1.x > startOfToday.valueOf()))).length;
  const numTotalData = flatten(series.map(d => d.data)).length;
  // not the best way to determine this, but it works
  const isTodayData = numAfterTodayStart > (numTotalData/2);
  const maxX = Math.max(...flatten(series.map(({data}) => data.map(({x}) => x))));
  const yAxes: any = [{title: {text: yAxisTitle, useHTML: true}, softMin: 0, labels: {formatter: yAxisFormatter}}];
  if (secondaryYAxisTitle) {
    yAxes.push({
      title: {text: secondaryYAxisTitle, useHTML: true},
      opposite: true,
      softMin: 0,
    });
  }
  return {
    chart: {
      type: chartType,
    },
    time: {
      // Positive offset means WEST of GMT, negative means EAST.
      timezoneOffset: dayjs().tz(regionToTz[region], true).utcOffset() * -1,
    },
    title: {
      text: title,
    },
    series,
    plotOptions: {
      ...extraPlotOptions,
    },
    xAxis: {
      title: {
        text: ''
      },
      type: 'datetime',
      minPadding: 0.05,
      maxPadding: 0.05,
      max: isTodayData ? endOfToday.valueOf() : maxX,
      min: minX,
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        y: 25,
      },
      tickInterval: 3600 * 1000,
      plotLines: [{
        color: 'var(--color-blue-2)',
        label: {
          text: 'Now',
        },
        dashStyle: 'ShortDash',
        value: Date.now()
      }],
    },
    credits: {enabled: false},
    yAxis: yAxes,
    exporting: {
      enabled: true,
      scale: 4,
      buttons: {
        contextButton: {
          symbol: "menuball",
          menuItems: ["viewFullscreen", "separator", "downloadCSV", "downloadPNG", "downloadJPEG", "downloadSVG"],
        },
      },
      menuItemDefinitions: {
        downloadCSV: {
          onclick: function () {
            document.getElementById(Constants.DOWNLOAD_BUTTON_ID).click();
          },
          text: 'Download CSV'
        }
      },
      chartOptions: {
        navigator: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        chart: {
          style: {
            fontFamily: Constants.FONT_FAMILY,
          },
        }
      },
      fallbackToExportServer: false
    },
  }
};


const makeFuelMixChartOptions = (data: IGetRealtimeDataResponse, region: string) => {
  const seriesData: Record<string, {x: number, y: number}[]> = {};

  data.fuelMix.forEach(fm => {
    // split the series of one fuel mix per timestamp into
    // each fuel as a time series with a timestamp
    Object.entries(fm.data).forEach(([fuelMw, mw]) => {
      const fuelName = snakeCase(fuelMw).replace('_mw', '');
      if (!seriesData[fuelName]) {
        seriesData[fuelName] = [];
      }
      seriesData[fuelName].push({y: mw, x: new Date(fm.startDate).valueOf()});
    })
  });

  const series = Object
    .entries(seriesData)
    // MISO specifically asked us to not show oil or wood, since they don't report it but it's in our data
    .filter(([fuelName, _]) => !['oil', 'wood'].includes(fuelName) || region !== 'MISO')
    .map(([fuelName, fuelData]) => ({name: fuelName.replaceAll('_', ' '), color: fuelCategoryPalette[fuelName], data: fuelData, tooltip: {valueSuffix: 'MW'}}))
    .sort((d1, d2) => {
      return Constants.FUEL_DISPLAY_ORDER.indexOf(d2.name) - Constants.FUEL_DISPLAY_ORDER.indexOf(d1.name);
    });

  return makeChartOptions(series, 'area', '', 'Generation', {
    series: {
      stacking: 'normal',
      dataGrouping: {
        enabled: false
      },
    },
    area: {marker: {enabled: false}},
  }, undefined, region, ({value}: {value: number}) => {
    if (value > 1000) {
      return `${value / 1000} GW`;
    } else if (value !== 0) {
      return `${value} MW`;
    } else {
      return '0';
    }
  });
}

const makeEmissionsChartOptions = (data: IGetRealtimeDataResponse, region: string, isImperialUnits: boolean) => {
  const factors = data.emissionFactors;
  const hourlyData = data.fuelMix
    .map(({data, startDate}) => ({
      x: new Date(startDate).valueOf(),
      y: arraySum(Object.entries(data).map(([fuelname, fuelMwh]) => {
        const factor = (factors[fuelname.replace('Mw', '')] || 0) / (isImperialUnits ? 1 : 2.2);
        return Math.ceil(factor * fuelMwh / (isImperialUnits ? 2000 : 1000));
      }))
    })).filter(({y}) => y !== 0);

  const generatedData = data.carbonIntensity.generated
    .map(ci => ({y: Math.round(isImperialUnits ? ci.data.generatedRateLbPerMwh : ci.data.generatedRateKgPerMwh), x: new Date(ci.startDate).valueOf()}))
    .filter(({y}) => y !== 0);

  return makeChartOptions(
    [
      {name: 'Generated Carbon Intensity', data: generatedData, color: 'red', type: 'spline', zIndex: 1},
      {data: hourlyData, name: 'Total Carbon Emissions', yAxis: 1},
    ],
    'areaspline',
    '',
    `intensity - CO<sub>2</sub> ${isImperialUnits ? 'lbs' : 'kg'}/MWh`,
    {areaspline: {marker: {enabled: false}}, spline: {marker: {enabled: false}}},
    'emissions - tons CO<sub>2</sub>',
    region,
  );
}

const makePieChartOptions = (viewingEmissions: boolean, data: IGetRealtimeDataResponse, queryStartDate: Dayjs, isImperialUnits: boolean) => {
  const isViewingToday = dayjs().startOf('day').valueOf() <= queryStartDate.valueOf();

  const title = viewingEmissions ? `${isViewingToday ? 'Latest': 'Average'} Carbon Emission Mix` : `${isViewingToday ? 'Latest' : 'Average'} Generated Fuel Mix`;
  let subtitle = '';
  if (viewingEmissions) {
    if (isViewingToday) {
      subtitle = 'tons of CO<sub>2</sub> emitted per hour at rate of electricity generation';
    } else {
      subtitle = 'tons of CO<sub>2</sub> emitted per hour on average throughout the day';
    }
  } else {
    if (isViewingToday) {
      subtitle = 'Latest mix of fuels producing electricity';
    } else {
      subtitle = 'Average mix of fuels producing electricity';
    }
  }
  const createSeriesFromRawData = (gen: Record<string, number>) => {
    return Object.entries(gen).map(([fuel, mw]) => {
      const fuelname = snakeCase(fuel).replace('_mw', '').replaceAll('_', ' ');
      let num = Number(mw);
      const factor = (data.emissionFactors[fuel.replace('Mw', '')] || 0) / (isImperialUnits ? 1 : 2.2);
      if (viewingEmissions) {
        num = Math.round((factor * num) / (isImperialUnits ? 2000 : 1000));
      }
      return {
        name: fuelname,
        y: Math.ceil(num),
        color: fuelCategoryPalette[snakeCase(fuel).replace('_mw', '')],
      }
    })
    .filter(({ y }) => y > 0)
    .sort((a, b) => b.y - a.y);
  }

  const createPlotOptions = (series: any) => ({
    chart: {
      backgroundColor: 'transparent',
      type: 'pie',
    },
    title: {text: title, y: 40},
    subtitle: {text: subtitle, useHTML: true, y: 60, style: {fontSize: '10px'}},
    series: [{
      name: viewingEmissions ? 'CO2 tons in the latest 5 minutes' : 'MW generated',
      data: series,
      size: '80%',
      innerSize: '60%',
      marker: {
        enabled: true
      },
    }],
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: 15,
          style: {
            fontSize: '11px',
            fontWeight: '400',
          }
        },
      }
    },
    tooltip: {
      pointFormat: `<b>{point.y}${viewingEmissions ? ' tons CO<span style="font-variant-position: sub;">2</span>' : ' MW'}</b> ({point.percentage:.1f}%)`
    },
    credits: {enabled: false},
    exporting: {enabled: false},
  })

  const avgFuelMix: Record<string, number> = {};

  data.fuelMix.forEach(gfm => {
    Object.entries(gfm.data).forEach(([fuel, mw]) => {
      if (avgFuelMix[fuel]) {
        avgFuelMix[fuel] = avgFuelMix[fuel] + (mw / (data.fuelMix.length || 1));
      } else {
        avgFuelMix[fuel] = mw / (data.fuelMix.length || 1);
      }
    });
  })

  return createPlotOptions(createSeriesFromRawData(isViewingToday ? data.fuelMix.at(-1)?.data : avgFuelMix));
}


const dataIsEmpty = (data: IGetRealtimeDataResponse) => {
  return data.fuelMix.length === 0;
}


const NoDataWarning = () => {
  const dispatch = useAppDispatch();

  const useYesterday = () => {
    dispatch(updateAppState({
      queryStartDate: dayjs().startOf('day').subtract(1, 'day'),
      queryEndDate: dayjs().endOf('day').subtract(1, 'day'),
    }));
  }

  return (
    <div className="realtime-empty-state--container">
      <div className="realtime-empty-state-content--container">
        <div className="realtime-empty-state-icon--container"><CloudOffRounded className="realtime-empty-state--icon" /></div>
        <div className="realtime-empty-state-explanation--container">
          <h3 className="realtime-empty-state-explanation--header">We don't appear to have data!</h3>
          <p className="realtime-empty-state-explanation--text">Apologies, but we don't appear to have data for the selected day. Perhaps try yesterday's data.</p>
          <Button variant='contained' onClick={useYesterday} className="realtime-empty-state-explanation--cta">Use Yesterday's Data</Button>
        </div>
      </div>
    </div>
  )
};


const RealTimeDataView = ({
  data,
  isLoading,
  noData,
}: {data: IGetRealtimeDataResponse, isLoading: boolean, noData: boolean}) => {
  const appState = useAppSelector(currentAppState);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last<IMapState | undefined>(mapHistory);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const start = appState.queryStartDate;
    const end = appState.queryEndDate;

    if (end.diff(start, 'day') > 1) {
      dispatch(updateAppState({
        queryStartDate: dayjs().startOf('day'),
        queryEndDate: dayjs().endOf('day'),
      }));
    }

    if (appState.regionSource === 'ISO' && mapState?.mapLevel !== MapLevel.ISOs) {
      const firstSelectedRegion = [...(mapState?.mapSelection || [])][0];
      const setRegionTo = !firstSelectedRegion || !getDoesRegionExistInLevel(firstSelectedRegion, MapLevel.ISOs) ? 'MISO' : firstSelectedRegion;
      if (mapState?.mapSelection.size === 0) {
        dispatch(changeMapLevel({level: MapLevel.ISOs, selectedRegion: setRegionTo}));
      } else {
        dispatch(changeMapLevel({level: MapLevel.ISOs, selectedRegion: setRegionTo}));
      }
    }

    if (appState.emissionAdjustment === EmissionAdjustment.Unadjusted || appState.emissionAdjustment === EmissionAdjustment.Adjusted) {
      dispatch(updateAppState({emissionAdjustment: EmissionAdjustment.ForElectricity}));
    }
  }, [appState.queryEndDate, appState.emissionAdjustment, appState.queryStartDate, dispatch, mapState, appState.regionSource]);

  const rows: (string | number)[][] = [];
  if (!isLoading && data && data.fuelMix.length > 0) {
    rows.push([
      'start_date_utc',
      'total_net_generation_mwh',
      `total_${appState.pollutant.toLowerCase()}_mass_tons`,
      `total_${appState.pollutant.toLowerCase()}_intensity_lbs_per_mwh`
    ]);
    Object.keys(data.fuelMix[0].data).sort().forEach(fuel => {
      rows[0].push(
        `${snakeCase(fuel)}_net_generation_mwh`,
        `${snakeCase(fuel)}_${appState.pollutant.toLowerCase()}_mass_tons`,
        `${snakeCase(fuel)}_${appState.pollutant.toLowerCase()}_intensity_lbs_per_mwh`
      );
    });

    const gfmByStartDate = keyBy(data.fuelMix, property("startDate"));
    const ciByStartDate = keyBy(data.carbonIntensity.generated, property("startDate"));
    const dividend = appState.timeResolution === '5m'? 12 : 1;
    const duration = durationToDuration(appState.timeResolution);
    let interval = dayjs(data.fuelMix[0].startDate);
    Object.entries(gfmByStartDate).forEach(([startDate, gfm]) => {
      while (dayjs(startDate) > interval) {
        rows.push([interval.utc().format()]);
        interval = interval.add(duration);
      }
      const ci = ciByStartDate[startDate];
      const netGenerationMWh = sum(Object.values(gfm.data)) / dividend;
      const row = [
        startDate,
        +netGenerationMWh.toFixed(2),
        +(ci.data.generatedRateLbPerMwh * netGenerationMWh / 2000).toFixed(2),
        +ci.data.generatedRateLbPerMwh.toFixed(2)
      ];
      Object.keys(gfm.data).sort().forEach(fuel => {
        const mwh = (gfm.data as any)[fuel] / dividend;
        const factor = data.emissionFactors[fuel.slice(0, -2)];
        row.push(+mwh.toFixed(2), +(mwh * factor / 2000).toFixed(2), +factor.toFixed(2));
      });
      rows.push(row);
      interval = interval.add(duration);
    });
  }

  useEffect(() => {
    const button = document.getElementById(Constants.DOWNLOAD_BUTTON_ID);
    if (button) {
      button.onclick = createDownloadFunction(appState, mapState, rows);
    }
  });

  const dataPanelStyle = {
    overflow: 'visible'
  };
  if (getBrowserWidth() >= 1200) {
    dataPanelStyle['overflow'] = 'scroll';
  }

  if (noData) {
    return <div className="realtime-data-view--empty">Click on a region in the map to see real time data</div>
  }

  if (isLoading || !data) {
    return <div className="realtime-data-view--loading">
        <div className="realtime-data-view-loading--text">Loading...</div>
        <LinearProgress />
        {appState.timeResolution === '5m' && <div className="realtime-data-view-loading--help">Searching through over a billion data points –– this might take a second.</div>}
      </div>
  }

  const isImperialUnits = appState.units === Units.Imperial
  const isViewingToday = dayjs().startOf('day').valueOf() <= appState.queryStartDate.valueOf();
  const latestCIData = data.carbonIntensity.generated.at(-1)?.data
  const latestCI = isImperialUnits ? (latestCIData?.generatedRateLbPerMwh || 0) : (latestCIData?.generatedRateKgPerMwh || 0);
  const averageCIMaybeNaN = arrayMean(data.carbonIntensity.generated.map(({data}) => isImperialUnits ? data.generatedRateLbPerMwh : data.generatedRateKgPerMwh));
  const averageCI = isNaN(averageCIMaybeNaN) ? 0 : averageCIMaybeNaN;
  const latestGfm: Record<string, number> = data.fuelMix.at(-1)?.data || {};
  const latestPctCFE = arraySum(Object.entries(latestGfm).filter(([fuel, _]) => cfeFuels.has(fuel)).map(([_, mw]) => mw)) / (arraySum(Object.values(latestGfm)) || 1);
  const currentEmissionsPerHour = latestCI * arraySum(Object.values(latestGfm)) / (isImperialUnits ? 2000 : 1000);
  const averageMWGen = arrayMean(data.fuelMix.map(gfm => arraySum(Object.values(gfm.data))));
  const averagePctCFE = arrayMean(data.fuelMix.map(gfm => arraySum(Object.entries(gfm.data).filter(([fuel, _]) => cfeFuels.has(fuel)).map(([_, mw]) => mw)))) / (averageMWGen || 1);
  const averageEmissionsPerHour = averageCI * averageMWGen / (isImperialUnits ? 2000 : 1000);

  if (dataIsEmpty(data)) {
    return <NoDataWarning />;
  }

  return (
    <Box sx={dataPanelStyle} className="data-view-container">
      <Box display="flex" mt={2} justifyContent="space-around" className='realtime-tour-topline-metrics'>
        <div className="data-view-topline-metric--container">
          <div className="data-view-topline-metric--title">{isViewingToday ? 'Current' : 'Average'} Carbon Intensity</div>
          <div className="data-view-topline-metric--value">{Math.ceil(isViewingToday ? (latestCI || 0) : averageCI).toLocaleString()}</div>
          <div className="data-view-topline-metric--help">{isImperialUnits ? 'lbs' : 'kg'} CO<sub>2</sub> per MWh</div>
        </div>
        <div className="data-view-topline-metric--container">
          <div className="data-view-topline-metric--title">Emissions per Hour</div>
          <div className="data-view-topline-metric--value">{Math.ceil(isViewingToday ? currentEmissionsPerHour : averageEmissionsPerHour).toLocaleString()}</div>
          <div className="data-view-topline-metric--help">tons CO<sub>2</sub> at {isViewingToday ? 'current' : 'average daily'} fuel mix</div>
        </div>
        <div className="data-view-topline-metric--container">
          <div className="data-view-topline-metric--title">Percent Carbon Free Energy</div>
          <div className="data-view-topline-metric--value">{Math.floor((isViewingToday ? latestPctCFE : averagePctCFE) * 100)}%</div>
          <div className="data-view-topline-metric--help">of {isViewingToday ? 'current' : 'average'} fuel mix</div>
        </div>
      </Box>
      <Box display="flex" mt={2} className="realtime-tour-pie-charts">
        <div className="data-view-sibling-chart--container">
          <HighchartsReact highcharts={Highcharts} options={makePieChartOptions(false, data, appState.queryStartDate, isImperialUnits)} />
        </div>
        <div className="data-view-sibling-chart--container">
          <HighchartsReact highcharts={Highcharts} options={makePieChartOptions(true, data, appState.queryStartDate, isImperialUnits)} />
        </div>
      </Box>
      <Box mt={2} className="realtime-tour-emissions-chart">
        <label className="realtime-chart--title">Total System Emissions & Carbon Intensity
          <HelpPopup popupContent={
            <>
              Times are shown in Eastern Standard Time.
              The red line shows the rate of emissions for grid consumption based on the fuel mix.
              The blue area shows the total emissions based on the fuels used for generation.
              The two of them are correlated directly, and the blue area also factors in the total generation.
              The red line describes the mix of fuels and the blue area describes the emissions from that mix.
            </>}
          />
        </label>
        <HighchartsReact highcharts={Highcharts} options={makeEmissionsChartOptions(data, [...mapState.mapSelection][0], isImperialUnits)} />
      </Box>
      <Box sx={{width: "92%"}} mt={2} className="realtime-tour-fuelmix-chart">
        <label className="realtime-chart--title">Grid fuel mix</label>
        <HighchartsReact highcharts={Highcharts} options={makeFuelMixChartOptions(data, [...mapState.mapSelection][0])} />
      </Box>
    </Box>
  );
}


export default RealTimeDataView;
