import React, { useEffect, useMemo, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

import * as Constants from 'constants/constants';
import { useAppDispatch } from 'modules/store';
import { setUserShouldDoRealtimeTour, } from 'modules/tour/slice';
import './style.css';

const tourCompletionCookieName = 'Singularity.MarginalEmissionsMap.tourVersion';

const steps: Step[] = [
  {
    target: 'body',
    placement: 'center',
    content: (
      <div style={{textAlign: 'left'}}>
        <h1 style={{fontSize: '24px'}}><strong>Marginal Fuel Mix and carbon intensity</strong></h1>
        <div className="realtime-tour-start--explaination">
          <strong>This dashboard visualizes the marginal fuel mix and marginal carbon intensity from the MISO Energy Grid.</strong>
          <p>
            We offer data for the past 2 years for the MISO region.
          </p>
          <p>
          This page is populated using data from MISO's real time <a href="https://www.misoenergy.org/markets-and-operations/real-time--market-data/market-reports/#nt=%2FMarketReportType%3AReal-Time%2FMarketReportName%3AReal-Time%20Fuel%20on%20the%20Margin%20(xlsx)&t=10&p=0&s=MarketReportPublished&sd=desc">fuel on the margin report</a>, which identifies
          the fuel types and MISO subregion of the marginal generators in each 5-minute market interval.
          The marginal generators are the units that would be dispatched to serve the next 1 MW of energy.
          The marginal carbon intensity is calculated by weighting the average fuel- and subregion-specific
          carbon intensity of each marginal fuel by the marginal fuel mix. The emission factors are sourced
          from the most recent year of available data from Singularity’s Open Grid Emissions dataset.
          </p>
        </div>
        <p className="realtime-tour-start--prompt">Click 'Next' for a quick tour.</p>
      </div>
    ),
  },
  {
    target: '.realtime-tour-data-resolution',
    content: 'For this page, only 5-minute data is available.',
    placement: 'right'
  },
  {
    target: '.realtime-tour-date-picker',
    content: 'Click on the date to open the date picker. Note that you can only look back up to 2 years.',
  },
  {
    target: '.realtime-tour-fuelmix-chart',
    content: <div>This chart shows the selected day's marginal fuel mix percentage trend.<small className="realtime-tour-text--small">Note that the times are reported in Eastern Standard Time. The marginal mix is based on the number of "units" that make up the marginal fuels. For example, if natural gas has 1 unit and wind has 1 unit, then they each make up 50% of the marginal fuels at that time.</small></div>,
  },
  {
    target: '.realtime-tour-emissions-chart',
    content: <div>
      This chart shows the selected day’s marginal carbon intensity trend.
      <small className="realtime-tour-text--small">Note that the times are reported in Eastern Standard Time.</small>
      </div>
  },
  {
    target: '.realtime-tour-last-updated',
    content: <div>This indicates the latest time that MISO has reported data.<small className="realtime-tour-text--small">Note that this is in your local time.</small></div>
  },
  {
    target: '.tour--start-over',
    content: (
      <div>
        <h5><strong>Done!</strong></h5>
        <br />
        <p>You can click here to replay the walkthrough at any time.</p>
        <p>For more information, check out the <a href='https://help.misoenergy.org/knowledgebase/article/KA-01501/en-us' target='_blank'>MISO Help Center</a> and Singularity's <a href={Constants.DASHBOARD_DOCS_URL} target='_blank'>Open Grid Emissions documentation</a>.</p>
      </div>
    ),
  },
];


const MarginalJoyrideTour = () => {
  const dispatch = useAppDispatch();

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    // Check if the user already skipped or finished the tour. If so, don't show it to them again.
    const maybeTourVersion = localStorage.getItem(tourCompletionCookieName);
    console.debug('[singularity] Tour cookie:', maybeTourVersion);
    if (maybeTourVersion !== Constants.CURRENT_TOUR_VERSION) {
      console.debug('[singularity] The user hasn\'t done this version of the tour, starting from the beginning.');
      setStepIndex(0);
      setRun(true);
    }

    document.getElementById(Constants.TOUR_BUTTON_ID).onclick = startTour;
  }, []);

  // This restarts the tour from the beginning.
  const startTour = () => {
    console.debug('[singularity] Restarting tour');
    localStorage.setItem(tourCompletionCookieName, 'invalidated');
    setStepIndex(0);
    setRun(true);
  }

  const handleJoyrideCallback = useMemo(() => (data: CallBackProps) => {
    const { action, index, status, type } = data;

    // If the tour is FINISHED or SKIPPED, set the 'run' state to false so the
    // tour can be started again later.
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      console.debug('[singularity] User finished/skipped tour. Saving a cookie to remember.');
      localStorage.setItem(tourCompletionCookieName, Constants.CURRENT_TOUR_VERSION);
      setStepIndex(0);
      setRun(false);
      // Important! Set the global userShouldDoTour so that another button click will restart.
      dispatch(setUserShouldDoRealtimeTour(false));
      return;
    }

    // Always allow the user to step backwards.
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
      setStepIndex(index - 1);
    } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      setStepIndex(index + 1);
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      console.error('Target for joyride tour not found!');
      setRun(false);
    }
  }, [dispatch]);

  return (
    <div className="joyride-tour">
      <Joyride
        continuous
        hideBackButton
        hideCloseButton
        run={run}
        callback={handleJoyrideCallback}
        scrollToFirstStep
        showProgress
        spotlightClicks
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#0082ca',
            backgroundColor: '#fff',
          },
        }}
      />
    </div>
  );
}


export default MarginalJoyrideTour;
