import { API_URL } from 'constants/constants'

interface IAttribute {
  name: string
  namespace: string
  type: string
  value: string | boolean | number | Record<string, string | number | boolean | string[]>
}

export interface IUser {
  id: string
  name: string
  role: string
  status: string
  email: string
  customer_id: string
  attributes: IAttribute[]
}

export interface ICustomer {
  id: string
  name: string
  status: string
  attributes: IAttribute[]
  api_key_id: string
  api_key_value: string
  usage_plan_id: string
}

export const fetchBootData = (): Promise<{
  user: null | IUser,
  customer: null | ICustomer,
}> => (
  fetch(`${API_URL}/boot`, {credentials: 'include'})
    .then(res => res.json())
    .then(jsonres => {
      if ('user' in jsonres) {
        return jsonres;
      } else {
        return {
          user: null,
          customer: null,
        };
      }
    })
    .catch(() => ({user: null, customer: null}))
)