import Container from "@mui/material/Container";
import Navbar from "components/GenericNavbar";
import * as Constants from "constants/constants";
import * as React from "react";
import "./stoplight.scss";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'elements-api': ElementsProps;
    }
  }
}

// https://meta.stoplight.io/docs/elements/b074dc47b2826-elements-configuration-options
interface ElementsProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  apiDescriptionUrl: string,
  router: "history" | "hash" | "memory" | "static",
  hideTryIt: boolean
}

const DocumentationPage = () => {
  return <div className="App">
    <Container maxWidth={false} disableGutters={true}>
      <Navbar />
      <div className="stoplight">
        <elements-api apiDescriptionUrl={`${Constants.API_URL}/api/v1/carbonflube/api-spec`} router="hash" hideTryIt={true} />
      </div>
    </Container>
  </div>
}

export default DocumentationPage;