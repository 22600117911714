import { Duration, MonthOrSeason, Pollutant } from "constants/enums";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { snakeCase } from "lodash";
import React from "react";

dayjs.extend(duration);

// Find the lowest order of magnitude at which all `numbers` can be expressed
// without any being a decimal (< 1.0).
export function findLowestCommonOom(numbers: number[], itemsAllowedBelowOne: number = 0): number {
  const oomToTry = [1, 1E3, 1E6, 1E9, 1E12, 1E15];
  let i = oomToTry.length - 1;
  // Find the first order of magnitude at which this number can be expressed
  // as something greater than a decimal.
  for (i = oomToTry.length - 1; i > 0; i--) {
    // let allNumbersAreGreater = true;
    let itemsBelowOne = 0;
    numbers.forEach((num: number) => {
      if (num < oomToTry[i] && num > 0) {
        itemsBelowOne += 1;
        // allNumbersAreGreater = false;
      }
    });
    // If every number is > this OOM, return it!
    if (itemsBelowOne <= itemsAllowedBelowOne) {
      return oomToTry[i];
    }
  }

  // If we didn't find a lowest common OOM, that means it must be < 1.
  return oomToTry[0];
}


// Copied from https://stackoverflow.com/a/9462382
export function formatNumber(num: number, digits: number,
                             orderOfMagnitude: number | null = null): string {
  if (num === 0) {
    return "0";
  }
  if (!num || isNaN(num)) {
    return null;
  }

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  // If an order of magnitude is given, use that one.
  if (orderOfMagnitude) {
    const symbol = {
      1: "",
      1E3: "k",
      1E6: "M",
      1E9: "B",
      1E12: "T",
      1E15: "Q"
    }[orderOfMagnitude];
    return (num / orderOfMagnitude).toFixed(digits).replace(rx, "$1") + symbol;
  }

  // Otherwise, figure out the best order of magnitude.
  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "Q" }
  ];
  let i;
  // Find the first order of magnitude at which this number can be expressed
  // as something greater than a decimal.
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}


export function arraySum(array: number[]): number {
  const initialValue = 0;
  return array.reduce(
    (pre, cur) => pre + cur,
    initialValue
  );
}

export function arrayMean(array: number[]): number {
  const sum = arraySum(array);
  const mean = sum / array.length;
  if (isNaN(mean)) {
    return 0;
  } else {
    return mean;
  }
}

export function arrayMax(array: number[]): number {
  if (array.length === 0) {
    return 0;
  }
  return array.reduce((pre, cur) => (cur > pre && !Number.isNaN(cur) && cur !== Infinity) ? cur : pre, array[0]);
}

export function formatPollutant(p: Pollutant) {
  return {
    [Pollutant.CO2]: <span>CO<sub>2</sub></span>,
    [Pollutant.CO2e]: <span>CO<sub>2</sub>e</span>,
    [Pollutant.NOx]: <span>NO<sub>X</sub></span>,
    [Pollutant.SO2]: <span>SO<sub>2</sub></span>
  }[p];
}


// https://stackoverflow.com/questions/31128855/comparing-ecma6-sets-for-equality
export function equalSets<T>(as: Set<T>, bs: Set<T>) {
  if (as.size !== bs.size) {
    return false;
  }
  for (let a of as) {
    if (!bs.has(a)) {
      return false;
    }
  }
  return true;
}


export function monthOrSeasonToString(m: MonthOrSeason): string {
  return {
    [MonthOrSeason.All]: 'All',
    [MonthOrSeason.Jan]: 'Jan',
    [MonthOrSeason.Feb]: 'Feb',
    [MonthOrSeason.Mar]: 'Mar',
    [MonthOrSeason.Apr]: 'Apr',
    [MonthOrSeason.May]: 'May',
    [MonthOrSeason.Jun]: 'Jun',
    [MonthOrSeason.Jul]: 'Jul',
    [MonthOrSeason.Aug]: 'Aug',
    [MonthOrSeason.Sep]: 'Sep',
    [MonthOrSeason.Oct]: 'Oct',
    [MonthOrSeason.Nov]: 'Nov',
    [MonthOrSeason.Dec]: 'Dec',
    [MonthOrSeason.Q1]: 'Q1',
    [MonthOrSeason.Q2]: 'Q2',
    [MonthOrSeason.Q3]: 'Q3',
    [MonthOrSeason.Q4]: 'Q4',
    [MonthOrSeason.SY1]: 'SY1',
    [MonthOrSeason.SY2]: 'SY2',
    [MonthOrSeason.SY3]: 'SY3',
    [MonthOrSeason.SY4]: 'SY4',
  }[m];
}


export function replaceAll(string: string, search: string, replace: string) {
  return string.split(search).join(replace);
}


// https://stackoverflow.com/questions/1038727/how-to-get-browser-width-using-javascript-code
export function getBrowserWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}


export function formatFuelCategoryName(f: string) {
  return snakeCase(f).replace('_', ' ');
}


// Replacement for the Array.at(-1) functionality (not supported in all browsers).
export function last<T>(array: T[]): T {
  if (array.length === 0) {
    return undefined;
  }
  return array[array.length - 1];
}

export function durationToDuration(duration: Duration): duration.Duration {
  return {
    [Duration.Five_Minute]: dayjs.duration(5, 'minutes'),
    [Duration.Hour]: dayjs.duration(1, 'hours'),
    [Duration.Day]: dayjs.duration(1, 'days'),
    [Duration.Month]: dayjs.duration(1, 'months'),
    [Duration.Subyear]: dayjs.duration(3, 'months'),
    [Duration.Year]: dayjs.duration(1, 'years')
  }[duration];
}