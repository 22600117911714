import Description from '@mui/icons-material/Description';
import FileDownload from '@mui/icons-material/FileDownload';
import IntegrationInstructions from '@mui/icons-material/IntegrationInstructions';
import SettingsIcon from '@mui/icons-material/Settings';
import TourIcon from '@mui/icons-material/Tour';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

import * as Constants from 'constants/constants';
import { useAppDispatch } from 'modules/store';
import { setUserShouldDoTour } from 'modules/tour/slice';
import { Link } from 'react-router-dom';


const IconHelpOptions = ({
  toggleSettingsMenu,
  hideExport,
  showDocs
}: {toggleSettingsMenu?: () => void, hideExport?: boolean, showDocs?: boolean}) => {
  const dispatch = useAppDispatch();

  return (
    <Grid item sx={{display: "flex", flexDirection: "column"}}>
      <Stack direction={"row"} spacing={2}>
        {!hideExport &&
          <Stack direction="column" justifyContent={"start"}>
            <label className="form-label--toolbar" style={{opacity: '0'}}>
              <a id={Constants.DOWNLOAD_LINK_ID}>_</a>
            </label>
            <Tooltip title="Download the current chart data" arrow>
              <IconButton
                id={Constants.DOWNLOAD_BUTTON_ID}
                className="tour--data-download"
              >
                <FileDownload/>
              </IconButton>
            </Tooltip>
          </Stack>
        }
        <Stack direction="column" justifyContent={"start"}>
          {/* Use this text as a placeholder, but don't show it. */}
          <label className="form-label--toolbar" style={{opacity: '0'}}>_</label>
          <Tooltip title="Open the settings menu" arrow>
            <IconButton onClick={toggleSettingsMenu} className="tour--settings-menu">
              <SettingsIcon/>
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack direction="column" justifyContent={"start"}>
          {/* Use this text as a placeholder, but don't show it. */}
          <label className="form-label--toolbar" style={{opacity: '0'}}>_</label>
          <Tooltip title="Show a dashboard walkthrough" arrow>
            <IconButton
              onClick={() => dispatch(setUserShouldDoTour(true))}
              className={'tour--start-over'}
              id={Constants.TOUR_BUTTON_ID}
            >
              <TourIcon/>
            </IconButton>
          </Tooltip>
        </Stack>
        {showDocs && <Stack direction="column" justifyContent={"start"}>
          <label className="form-label--toolbar" style={{opacity: '0'}}>_</label>
          <Tooltip title="Technical Documentation" arrow>
            <IconButton href={Constants.CARBON_FLOW_DOCUMENTATION_URL} className="tour--technical-docs">
              <Description/>
            </IconButton>
          </Tooltip>
        </Stack>}
        {showDocs && <Stack direction="column" justifyContent={"start"}>
          <label className="form-label--toolbar" style={{opacity: '0'}}>_</label>
          <Tooltip title="API Documentation" arrow>
            <IconButton component={Link} to="/api-docs" className="tour--api-docs">
              <IntegrationInstructions/>
            </IconButton>
          </Tooltip>
        </Stack>}
      </Stack>
    </Grid>
  );
}

export default IconHelpOptions;