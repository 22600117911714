import bbox from '@turf/bbox';
import { cloneDeep } from 'lodash';
import { LngLat, LngLatLike, Map, MapboxGeoJSONFeature, NavigationControl, Popup } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from "react-dom";
import Select from 'react-select';

import { IGetPlantMetadataParams, useGetPlantMetadataQuery } from 'api/data';
import { formatEntityCodes, formatFuelCategories } from 'api/utils';
import MapLegend from 'components/MapLegend';
import MapPlantPopup from 'components/MapPopup';
import CustomButtonControl from 'components/MapboxGLButtonControl';
import { rawModelBuiltIconSvg } from 'components/ModelBuiltIcon';
import * as Constants from 'constants/constants';
import { EntityType, FuelMapping, MapLevel, PlantType } from 'constants/enums';
import { PlantMetadata } from 'constants/interfaces';
import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { IMapState, changeMapLevel, editMapSelection, enterMapSelection, exitMapSelection } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { setUserDidClickPlant, setUserDidClickZoom } from 'modules/tour/slice';
import { fuelCategoryPalette, misoSubregionColorMap, rainbowColorMap10 } from 'utils/ColorPalette';
import { getDataAvailability } from 'utils/DataAvailability';
import { singularitySelectStyle } from 'utils/ReactSelectStyle';
import { last } from 'utils/utils';

import './style.css';
import { generateOverlapColors, getMapData, getMapOverlapData, loadSvgImage, makeCircleRadiusExpression, makeFuelColorMapping, makeModelBuiltIconSizeExpression, queryParamsAreComplete, shouldShowMapSubRegions } from './utils';

const seMapboxContainerId = 'mapbox-container';
const mapDataSource = 'map-data-source';
const plantDataSource = 'plant-data-source';
const plantDataLayer = 'plant-data-layer';
const modelBuiltPlantLayer = 'model-built-plant-layer';
const regionFillsLayer = 'region-fills';
const regionBordersLayer = 'region-borders';
const mapOverlapSource = 'map-overlap-source';
const mapOverlapLayer = 'map-overlap-layer';


// Store mapbox controls globally so that we can keep a reference to them.
var map: Map | null = null;
var mapNavigationControl = new NavigationControl({showCompass: false});
var mapBackButton: CustomButtonControl | null = null;
var mapZoomToSelectionButton: CustomButtonControl | null = null;
var labelPopup: Popup = null;

interface IMapLevelOption {
  value: MapLevel
  label: string
}

interface IMapRegionOption {
  value: string
  label: string
}


const MapView = ({
  allowMultipleRegions=true,
  allowedMapLevels=[MapLevel.BAs, MapLevel.ISOs, MapLevel.LARGE_BAs, MapLevel.States],
  showOnlyMISO=false,
  showLevelSelect=true,
  showRegionSelect=true,
  showPlants=true,
  showZoomButton=true
}: {
  allowMultipleRegions?: boolean,
  allowedMapLevels?: MapLevel[],
  showOnlyMISO?: boolean,
  showLevelSelect?: boolean,
  showRegionSelect?: boolean,
  showPlants?: boolean
  showZoomButton?: boolean
}) => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(currentAppState);
  const mapHistory = useAppSelector(currentMapHistory);

  const mapState: IMapState = cloneDeep(last(mapHistory));
  const [mapLoaded, setMapLoaded] = useState(false);

  const mapStateRef = useRef<IMapState>();
  mapStateRef.current = mapState;

  const popupRef = useRef(new Popup({ offset: 15 }));

  const entityType = {
    [MapLevel.BAs]: 'ba_region',
    [MapLevel.LARGE_BAs]: 'ba_region',
    [MapLevel.ISOs]: 'ba_region',
    [MapLevel.MISO]: 'ba_region',
    [MapLevel.Subregions]: 'ba_subregions',
    [MapLevel.States]: 'state',
    [MapLevel.MISO_States]: 'state',
    [MapLevel.MISO_Counties]: 'county',
    [MapLevel.LRZs]: 'ba_subregion',
    [MapLevel.LBAs]: 'ba_subregion',
  }[mapState.mapLevel] as EntityType;

  const queryParams: IGetPlantMetadataParams = {
    entityType: entityType,
    entityCodes: formatEntityCodes(entityType, Array.from<string>(mapState.mapSelection)),
    fuelCategories: formatFuelCategories(appState.fuelCategories),
    fuelMapping: appState.fuelMapping,
    start: appState.queryStartDate.format('YYYY-MM-DDT00:00:00-00:00'),
    end: appState.queryEndDate.format('YYYY-MM-DDT00:00:00-00:00')
  };

  const { data, isFetching, isError } = useGetPlantMetadataQuery(queryParams,
    { skip: !queryParamsAreComplete(appState, mapState) || !showPlants });

  let plantData: PlantMetadata[] = [];
  if (queryParamsAreComplete(appState, mapState) && !isFetching && !isError) {
    plantData = data?.data || [];
  }

  const onRegionOrSubRegionClicked = (apiMapLevel: string, apiRegionCode: string) => {
    console.debug(`[singularity] Clicked apiMapLevel=${apiMapLevel} apiRegionCode=${apiRegionCode}`);
    const mapState = mapStateRef.current;
    if (mapState.mapSelection.size >= 10 && !mapState.mapSelection.has(apiRegionCode)) {
      return;
    }
    // Check if the user clicked on a different map level (i.e subregion), or if they were clicking
    // on items at the same map level.
    const clickedMapLevel = apiMapLevel === 'subregion' ? MapLevel.LRZs : mapState.mapLevel;
    const clickedMapSelection = new Set<string>([apiRegionCode]);
    const editMode = mapState.mapSelection.has(apiRegionCode) ? 'remove' : (allowMultipleRegions ? 'update' : 'overwrite');
    dispatch(editMapSelection({ editMode: editMode, mapLevel: clickedMapLevel, mapSelection: clickedMapSelection }));
  }

  // This should be called when the user clicks the "Exit region" button, and
  // whenever all selected regions are cleared.
  const onExitOrClearRegions = useMemo(() => () => {
    console.debug('[singularity] Exiting or clearing currently selection regions.');
    dispatch(exitMapSelection());
  }, [dispatch])

  const onZoomToSelection = useMemo(() => () => {
    dispatch(enterMapSelection());
    dispatch(setUserDidClickZoom(true));
    if (showOnlyMISO && mapStateRef.current.mapLevel !== MapLevel.LRZs) {
      dispatch(changeMapLevel({level: MapLevel.LRZs}))
    }
  }, [dispatch, showOnlyMISO])

  // This function should add all of the sources and layers that the map will use. Other functions
  // change the map view by map.getSource(_).setData(_).
  const createMap = (onLoadCallback: () => void) => {
    const mapBoundingBox = [-124.73, 25.84, -66.98, 49.38];

    map = new Map({
      accessToken: Constants.MAPBOXGL_ACCESS_TOKEN,
      container: seMapboxContainerId,
      style: 'mapbox://styles/mapbox/light-v10',
      bounds: [mapBoundingBox[0] - 0.5, mapBoundingBox[1] - 0.5,
               mapBoundingBox[2] + 0.5, mapBoundingBox[3] + 0.5]
    });
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();
    // map.scrollZoom.disable();

    map.on('load', () => {
      map.addSource(mapDataSource, {
        type: 'geojson',
        data: { 'type': 'FeatureCollection', 'features': [] },
        cluster: false,
      });

      map.addSource(mapOverlapSource, {
        type: 'geojson',
        data: { 'type': 'FeatureCollection', 'features': [] },
        cluster: false,
      });

      // Add zoom and rotation controls to the map.
      map.addControl(mapNavigationControl);

      // The feature-state dependent fill-opacity expression will render the hover effect
      // when a feature's hover state is set to true.
      map.addLayer({
        'id': regionFillsLayer,
        'type': 'fill',
        'source': mapDataSource,
        'layout': {
          // Make sure that smaller BAs show up on top of larger ones so that they're clickable.
          'fill-sort-key': ['-', ['get', 'area']]
        },
        'paint': {
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.8,
            0.5
          ],
        }
      });

      generateOverlapColors(map, () => {
        if (!showOnlyMISO) {
          map.addLayer({
            'id': mapOverlapLayer,
            'type': 'fill',
            'source': mapOverlapSource,
            'layout': {
            },
            'paint': {
              'fill-opacity': 1.0,
              'fill-pattern': [
                'concat',
                'pattern-',
                ['-', ['get', 'id_1'], ['*', ['floor', ['/', ['get', 'id_1'], 10]], 10]],
                '-',
                ['-', ['get', 'id_2'], ['*', ['floor', ['/', ['get', 'id_2'], 10]], 10]]
              ]
            }
          });
        }
      });

      map.addLayer({
        'id': regionBordersLayer,
        'type': 'line',
        'source': mapDataSource,
        'layout': {
          'line-sort-key': [
            'case',
            ['boolean', ['get', 'selected'], false],
            100,
            99,
          ],
        },
        'paint': {
          'line-color': [
            'case',
            ['boolean', ['get', 'selected'], false],
            '#000000',
            '#D3D3D3'
          ],
          'line-width': 1.0,
        }
      });

      // Create a popup, but don't add it to the map yet.
      labelPopup = new Popup({ closeButton: false, closeOnClick: false });

      let hoveredRegionId: any = null;

      // When the user moves their mouse over the state-fill layer, we'll update the
      // feature state for the feature under the mouse.
      map.on('mousemove', regionFillsLayer, (e) => {
        if (e.features.length > 0) {
          if (hoveredRegionId !== null) {
            map.setFeatureState(
              { source: mapDataSource, id: hoveredRegionId },
              { hover: false }
            );
          }
          hoveredRegionId = e.features[0].id;
          map.setFeatureState(
            { source: mapDataSource, id: hoveredRegionId },
            { hover: true }
          );

          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = 'pointer';

          // Copy coordinates array.
          if (e.features[0].properties.centroid) {
            // Note that this property will be a string, so we need to JSON parse it here.
            const coordinates = JSON.parse(e.features[0].properties.centroid).coordinates;
            const description = e.features[0].properties.name;
            labelPopup.setLngLat(coordinates).setHTML(description).addTo(map);
          }
        }
      });

      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.
      map.on('mouseleave', regionFillsLayer, () => {
        if (hoveredRegionId !== null) {
          map.setFeatureState(
            { source: mapDataSource, id: hoveredRegionId },
            { hover: false }
          );
        }
        hoveredRegionId = null;

        // Remove label popup.
        map.getCanvas().style.cursor = '';
        labelPopup.remove();
      });

      // Add placeholder source/layer for plant data.
      map.addSource(plantDataSource, {
        type: 'geojson',
        data: { 'type': 'FeatureCollection', 'features': [] },
        cluster: false,
      });

      const fuelCategoryPaletteMapping = makeFuelColorMapping(appState);
      const colorBy = appState.fuelMapping === FuelMapping.Simple
        ? 'simplePrimaryFuelCategory' : 'primaryFuelCategory';

      map.addLayer({
        id: plantDataLayer,
        type: 'circle',
        source: plantDataSource,
        paint: {
          'circle-radius': makeCircleRadiusExpression(),
          'circle-stroke-width': [
            'match',
            ['get', 'plantType'],
            PlantType.ModelBuilt, 0, 1
          ],
          'circle-opacity': [
            'match',
            ['get', 'plantType'],
            PlantType.ModelBuilt, 0, 1
          ],
          'circle-color': [
            'match',
            ['get', colorBy],
            ...fuelCategoryPaletteMapping,
            fuelCategoryPalette['other'],
          ]
        },
      });

      loadSvgImage(rawModelBuiltIconSvg, (img: any) => {
        map.addImage(`model-built-plant-marker`, img, { sdf: true });
        map.addLayer({
          id: modelBuiltPlantLayer,
          type: 'symbol',
          source: plantDataSource,
          paint: {
            'icon-color': [
              'match',
              ['get', colorBy],
              ...fuelCategoryPaletteMapping,
              fuelCategoryPalette['other'],
            ],
            'icon-opacity': [
              'match',
              ['get', 'plantType'],
              PlantType.ModelBuilt, 1, 0
            ]
          },
          layout: {
            'icon-size': makeModelBuiltIconSizeExpression(),
            'icon-allow-overlap': true,
            'icon-anchor': 'center',
            'icon-image': 'model-built-plant-marker'
          }
        });
      });

      // Use one global click event handler so that we can implement custom behavior.
      map.on('click', (e) => {
        const features = map.queryRenderedFeatures(e.point, { layers: [regionFillsLayer, plantDataLayer] });
        if (features.length === 0) {
          return;
        }
        // For some reason, plants are duplicated in the map. This logic filters out the unique
        // plants that were clicked on, removing duplicates.
        const uniquePlantIds = new Set<string>(features
          .filter(value => value.layer.id === plantDataLayer)
          .map(value => value.properties.dedupKey));
        const clickedPlants = Array.from(uniquePlantIds).map((dedupKey: string) => {
          return features.find((value) => value.properties.dedupKey === dedupKey);
        });

        if (clickedPlants.length > 0) {
          multiplePlantsClicked(clickedPlants, e.lngLat);
        }
        if (features[0].layer.id === regionFillsLayer) {
          regionClickedCallback(features[0]);
        }
      });

      // Finally, update the map.
      onLoadCallback();
      setMapLoaded(true);
    });
  }

  const regionClickedCallback = (feature: MapboxGeoJSONFeature) => {
    if (feature.properties) {
      onRegionOrSubRegionClicked(
        feature.properties.apiRegionType, // NOTE(milo): Using the old name for compatibility with the GeoJSON files.
        feature.properties.apiRegionCode
      );
    }
  }

  const multiplePlantsClicked = (features: MapboxGeoJSONFeature[], lngLat: LngLat) => {
    if (features.length === 0) {
      return;
    }
    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    const coordinates = [lngLat.lng, lngLat.lat];
    while (Math.abs(lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const popupNode = document.createElement("div");
    ReactDOM.render(
      <MapPlantPopup features={features}/>,
      popupNode
    );

    popupRef.current
      .setLngLat(coordinates as LngLatLike)
      .setDOMContent(popupNode)
      .addTo(map);

    dispatch(setUserDidClickPlant(true));
  }

  const setExitButtonVisible = useMemo(() => () => {
    const show = (mapState.mapSelection.size > 0 ||
                  mapHistory.length > 1) && showZoomButton;
    if (show && !map.hasControl(mapBackButton)) {
      mapBackButton = new CustomButtonControl({
        className: 'mapbox-gl-exit-region tour--exit-region',
        title: 'Exit the current map selection.',
        eventHandler: onExitOrClearRegions.bind(this)
      });
      map.addControl(mapBackButton, 'top-left');
    }
    if (!show && map.hasControl(mapBackButton)) {
      map.removeControl(mapBackButton);
    }
  }, [mapHistory.length, mapState.mapSelection.size, onExitOrClearRegions, showZoomButton]);

  const setZoomButtonVisible = useMemo(() => () => {
    const show = mapState.mapSelection.size > 0 && !mapState.userEnteredSelection && showZoomButton;
    if (show && !map.hasControl(mapZoomToSelectionButton)) {
      mapZoomToSelectionButton = new CustomButtonControl({
        className: 'mapbox-gl-zoom-to-selection tour--zoom-to-selection',
        title: 'Zoom into the current map selection. Subregions will be shown if available.',
        eventHandler: onZoomToSelection.bind(this),
      });
      map.addControl(mapZoomToSelectionButton, 'top-left');
    }
    if (!show && map.hasControl(mapZoomToSelectionButton)) {
      map.removeControl(mapZoomToSelectionButton);
    }
  }, [mapState.mapSelection.size, mapState.userEnteredSelection, onZoomToSelection, showZoomButton]);

  // Update the map layers that are currently shown based on the props/state.
  const updateMap = useMemo(() => () => {
    if (!map || !map.getSource(mapDataSource) || !map.getSource(mapOverlapSource)) {
      return;
    }
    const mapDataToShow = getMapData(appState, mapStateRef.current, showOnlyMISO);
    const overlapDataToShow = getMapOverlapData(appState, mapStateRef.current);

    const mapBoundingBox = bbox(mapDataToShow);
    map.fitBounds([mapBoundingBox[0] - 0.5, mapBoundingBox[1] - 0.5,
                   mapBoundingBox[2] + 0.5, mapBoundingBox[3] + 0.5]);

    // @ts-ignore
    map.getSource(mapDataSource).setData(mapDataToShow);
    // @ts-ignore
    map.getSource(mapOverlapSource).setData(overlapDataToShow);
    const colorMap = mapStateRef.current.mapLevel === MapLevel.Subregions ? misoSubregionColorMap : rainbowColorMap10;
    map.setPaintProperty(regionFillsLayer, 'fill-color', [
      'match',
      ['-', ['get', 'id'], ['*', ['floor', ['/', ['get', 'id'], 10]], 10]],
      ...colorMap,
      '#000000'
    ]);


    setExitButtonVisible();
    setZoomButtonVisible();

    if (popupRef.current) {
      popupRef.current.remove();
    }

    const colorBy = appState.fuelMapping === FuelMapping.Simple
        ? 'simplePrimaryFuelCategory' : 'primaryFuelCategory';

    // Update the layer legend to show the Simple/Expanded fuel categories.
    const fuelCategoryPaletteMapping = makeFuelColorMapping(appState);
    map.setPaintProperty(plantDataLayer, 'circle-color', [
      'match',
      ['get', colorBy],
      ...fuelCategoryPaletteMapping,
      fuelCategoryPalette['other'],
    ]);
  }, [appState, setExitButtonVisible, setZoomButtonVisible, showOnlyMISO]);

  // Show new plant data after the API calls finishes.
  const updatePlantData = () => {
    if (!plantData) {
      return;
    }
    if (!map || !map.getSource(plantDataSource)) {
      return;
    }
    const newPlantData = {
      'type': 'FeatureCollection',
      'features': plantData
        .filter((plant: PlantMetadata) => (plant.latitude !== null && plant.longitude !== null))
        .map((plant: PlantMetadata) => {
          return {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [plant.longitude, plant.latitude]
            },
            'properties': {
              ...cloneDeep(plant),
              'plantType': plant.meta?.plantType || PlantType.Existing
            }
          };
        })
    };
    // @ts-ignore
    map.getSource(plantDataSource).setData(newPlantData);
  }

  useEffect(() => {
    createMap(updateMap);
    if (showOnlyMISO) {
      dispatch(editMapSelection({editMode: "overwrite", mapLevel: MapLevel.LRZs, mapSelection: new Set<string>()}));
    }
  }, [showOnlyMISO]);

  useEffect(() => {
    updateMap();
  }, [mapState.mapLevel, mapState.mapSelection.size, mapState.userEnteredSelection, updateMap, showOnlyMISO]);

  useEffect(() => {
    if (mapLoaded) {
      updatePlantData();
    }
  }, [plantData, mapLoaded]);

  const validMapLevels = Array.from(getDataAvailability(appState, mapState).mapLevels).filter(l => allowedMapLevels.includes(l));
  const mapLevelOptions = validMapLevels.map((mapLevel: MapLevel) => {
    const label = {
      [MapLevel.BAs]: "View BAs (All)",
      [MapLevel.LARGE_BAs]: "View BAs (Large)",
      [MapLevel.ISOs]: "View ISOs" ,
      [MapLevel.MISO]: "View MISO" ,
      [MapLevel.Subregions]: "View Subregions",
      [MapLevel.States]: "View States",
      [MapLevel.MISO_States]: "View States",
      [MapLevel.MISO_Counties]: "View Counties",
      [MapLevel.LRZs]: "View Local Resource Zones",
      [MapLevel.LBAs]: "View Local Balancing Authorities"
    }[mapLevel];
    return { value: mapLevel, label: label };
  });

  let allMapRegionOptions: IMapRegionOption[] = [];
  let selectedMapRegionOptions: IMapRegionOption[] = [];

  const showSubRegions = shouldShowMapSubRegions(appState, mapState);

  // Make option objects for all SELECTED map regions.
  Array.from(mapState.mapSelection).forEach((selectedRegion) => {
    // Format labels differently for LRZs.
    if (mapState.mapLevel === MapLevel.LRZs) {
      const option = { value: selectedRegion, label: `LRZ ${selectedRegion}` };
      selectedMapRegionOptions.push(option);
      allMapRegionOptions.push(option);
    } else {
      const option = { value: selectedRegion, label: selectedRegion };
      selectedMapRegionOptions.push(option);
      allMapRegionOptions.push(option);
    }
  });

  if (!mapState.userEnteredSelection) {
    // Get all of the available map regions that the user can click right now.
    const mapDataToShow = getMapData(appState, mapState, showOnlyMISO);
    const mapRegionCodes = mapDataToShow.features.map((f: any) => {
      return f.properties.apiRegionCode;
    }).sort();

    if (showSubRegions) {
      // If we're viewing LRZs, make sure to take 'MISO' out of the dropdown options.
      // TODO(milo): Eventually generalize this using the map state history.
      const parentRegion = 'MISO';
      mapRegionCodes.filter((el: string) => (el !== parentRegion)).forEach((el: string) => {
        allMapRegionOptions.push({ value: el, label: `LRZ ${el}` });
      });
    } else {
      mapRegionCodes.forEach((el: string) => {
        allMapRegionOptions.push({ value: el, label: el });
      });
    }
  }

  return (
    <div className="se--map-panel tour--map-panel">
      {showRegionSelect && <div className={`se--map-overlay-top-${showLevelSelect ? 'center' : 'right'}`}>
        <Select
          isMulti={allowMultipleRegions}
          className='react-select-toolbar tour--select-map-region'
          isClearable
          options={allMapRegionOptions}
          placeholder={'Select a map region'}
          isOptionDisabled={() => mapState.mapSelection.size >= 10}
          onChange={(value: IMapRegionOption[] | IMapRegionOption, _) => {
            if (!Array.isArray(value)) {
              value = [value];
            }
            if (value.length === 0 || value[0] === null) {
              onExitOrClearRegions();
              return;
            }
            if (showSubRegions) {
              // TODO(milo): Eventually generalize. Can't remember why we need to filter out the parent here.
              const parentRegion = 'MISO';
              const mapSelection = new Set(value.filter((el) => (el.value !== parentRegion)).map((el) => el.value));
              dispatch(editMapSelection({ editMode: 'overwrite', mapLevel: mapState.mapLevel, mapSelection: mapSelection }));
            } else {
              const mapSelection = new Set(value.map((el) => el.value));
              dispatch(editMapSelection({ editMode: 'overwrite', mapLevel: mapState.mapLevel, mapSelection: mapSelection }));
            }
          }}
          value={selectedMapRegionOptions}
        />
      </div>}
      {showLevelSelect && <div className="se--map-overlay-top-right tour--region-level-select">
        <Select
          className='react-select-toolbar'
          options={mapLevelOptions}
          onChange={(value: IMapLevelOption, _) => dispatch(changeMapLevel({level: value.value}))}
          value={mapLevelOptions.find(el => (el.value === mapState.mapLevel))}
          styles={singularitySelectStyle}
        />
      </div>}
      <div id={seMapboxContainerId}/>
      {showPlants && <div className="se--map-overlay-bottom-left">
        <MapLegend/>
      </div>}
    </div>
  );
}


export default MapView;
