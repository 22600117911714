import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import * as React from 'react';

import DataView from 'components/DataView';
import Navbar from 'components/GenericNavbar';
import ProjectionsJoyrideTour from 'components/JoyrideTour/projections';
import MapView from 'components/MapView';
import OptionsToolbar from 'components/OptionsToolbar';
import SettingsModal from 'components/SettingsModal';
import { Duration, EmissionFramework, MapLevel } from 'constants/enums';
import { HISTORICAL_END, PROJECTIONS_END, updateAppState } from 'modules/app/slice';
import { useAppDispatch } from 'modules/store';


const ProjectionsPage = () => {
  const [settingsMenuOpen, setSettingsMenuOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(updateAppState({
      queryStartDate: HISTORICAL_END.startOf('year'),
      queryEndDate: PROJECTIONS_END,
      timeResolution: Duration.Year,
      emissionFramework: EmissionFramework.Generated
    }));
  }, []);

  return <div className="App">
    <Container maxWidth={false} disableGutters={true}>
      <Navbar />

      <ProjectionsJoyrideTour />

      <SettingsModal
        open={settingsMenuOpen}
        setOpen={setSettingsMenuOpen}
      />

      <OptionsToolbar
        toggleSettingsMenu={() => setSettingsMenuOpen(!settingsMenuOpen)}
      />

      <Grid container>
        <Grid item xs={12} lg={7} order={{ xs: 2, lg: 1 }}>
          <DataView/>
        </Grid>
        <Grid item xs={12} lg={5} order={{ xs: 1, lg: 2 }}>
          <MapView allowedMapLevels={[MapLevel.ISOs, MapLevel.LRZs]} showOnlyMISO showPlants={false} showLevelSelect={false} />
        </Grid>
      </Grid>
    </Container>
  </div>
}

export default ProjectionsPage;