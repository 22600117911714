import React, { useEffect, useMemo, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

import * as Constants from 'constants/constants';
import { useAppDispatch } from 'modules/store';
import { setUserShouldDoRealtimeTour, } from 'modules/tour/slice';
import './style.css';

const tourCompletionCookieName = 'Singularity.RealtimeEmissionsMap.tourVersion';

const steps: Step[] = [
  {
    target: 'body',
    placement: 'center',
    content: (
      <div style={{textAlign: 'left'}}>
        <h1 style={{fontSize: '24px'}}><strong>Near Real-time Grid Map</strong></h1>
        <div className="realtime-tour-start--explaination">
          <strong>This dashboard visualizes near real-time emissions and generation from the MISO grid.</strong>
          <p>
            We offer data for the past 2 years, up to the most recent data published by MISO.
          </p>
          <p>
          The realtime page is populated by the public data on <a href="https://api.misoenergy.org/MISORTWD/dashboard.html?fuelMix" target="_blank">MISO's website</a>.
          The generated fuel mix data is then multiplied by the respective fuels’ emission factors in order to create data for a rate of emissions
          (the carbon intensity) and the grid’s system emissions. The emission factors are pulled from the most recently relevant year of the EPA’s eGRID data.
          As such, the emissions and rate is called a “generated” or “average” carbon intensity.
          </p>
        </div>
        <p className="realtime-tour-start--prompt">Click 'Next' for a quick tour.</p>
      </div>
    ),
  },
  {
    target: '.realtime-tour-data-resolution',
    content: 'Change the selection here to view the data in either 5-minute or hourly intervals for the line charts. The "hourly" selection will load data faster, but not be as detailed.',
    placement: 'right',
  },
  {
    target: '.realtime-tour-date-picker',
    content: 'Click on the date to open the date picker. Note that you can only look back up to 2 years. Click "Today" to quickly return to today\'s data.',
  },
  {
    target: '.realtime-tour-topline-metrics',
    content: 'Here you can see the status of the most recent data reported by MISO. This also includes the percentage of carbon-pollution free energy (CFE).',
  },
  {
    target: '.realtime-tour-pie-charts',
    content: 'These two charts show the latest mix of fuels powering MISO (left), and their relative contributions to the grid\'s carbon emissions.',
    placement: 'right',
  },
  {
    target: '.realtime-tour-emissions-chart',
    content: <div>
      This chart shows the selected day's trend of carbon intensity (rate of carbon emissions in pounds of carbon per Megawatt-hour of electricity generated) and carbon emissions in tons.
      <small className="realtime-tour-text--small">Note that the times are reported in Eastern Standard Time.</small>
      </div>
  },
  {
    target: '.realtime-tour-fuelmix-chart',
    content: <div>This chart shows the selected day's trend of fuels that make up the electricity on the grid.<small className="realtime-tour-text--small">Note that the times are reported in Eastern Standard Time.</small></div>,
  },
  {
    target: '.realtime-tour-last-updated',
    content: <div>This indicates the latest time that MISO has reported data.<small className="realtime-tour-text--small">Note that this is in your local time.</small></div>
  },
  {
    target: '.tour--start-over',
    content: (
      <div>
        <h5><strong>Done!</strong></h5>
        <br />
        <p>You can click here to replay the walkthrough at any time.</p>
        <p>For more information, check out the <a href='https://help.misoenergy.org/knowledgebase/article/KA-01501/en-us' target='_blank'>MISO Help Center</a> and Singularity's <a href={Constants.DASHBOARD_DOCS_URL} target='_blank'>Open Grid Emissions documentation</a>.</p>
      </div>
    ),
  },
];


const RealtimeJoyrideTour = () => {
  const dispatch = useAppDispatch();

  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    // Check if the user already skipped or finished the tour. If so, don't show it to them again.
    const maybeTourVersion = localStorage.getItem(tourCompletionCookieName);
    console.debug('[singularity] Tour cookie:', maybeTourVersion);
    if (maybeTourVersion !== Constants.CURRENT_TOUR_VERSION) {
      console.debug('[singularity] The user hasn\'t done this version of the tour, starting from the beginning.');
      setStepIndex(0);
      setRun(true);
    }

    document.getElementById(Constants.TOUR_BUTTON_ID).onclick = startTour;
  }, []);

  // This restarts the tour from the beginning.
  const startTour = () => {
    console.debug('[singularity] Restarting tour');
    localStorage.setItem(tourCompletionCookieName, 'invalidated');
    setStepIndex(0);
    setRun(true);
  }

  const handleJoyrideCallback = useMemo(() => (data: CallBackProps) => {
    const { action, index, status, type } = data;

    // If the tour is FINISHED or SKIPPED, set the 'run' state to false so the
    // tour can be started again later.
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      console.debug('[singularity] User finished/skipped tour. Saving a cookie to remember.');
      localStorage.setItem(tourCompletionCookieName, Constants.CURRENT_TOUR_VERSION);
      setStepIndex(0);
      setRun(false);
      // Important! Set the global userShouldDoTour so that another button click will restart.
      dispatch(setUserShouldDoRealtimeTour(false));
      return;
    }

    // Always allow the user to step backwards.
    if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
      setStepIndex(index - 1);
    } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
      setStepIndex(index + 1);
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      console.error('Target for joyride tour not found!');
      setRun(false);
    }
  }, [dispatch]);

  return (
    <div className="joyride-tour">
      <Joyride
        continuous
        hideBackButton
        hideCloseButton
        run={run}
        callback={handleJoyrideCallback}
        scrollToFirstStep
        showProgress
        spotlightClicks
        showSkipButton
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#0082ca',
            backgroundColor: '#fff',
          },
        }}
      />
    </div>
  );
}


export default RealtimeJoyrideTour;
