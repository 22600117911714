import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import Login from '@mui/icons-material/Login';
import Person from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import { AUTH_URL } from 'constants/constants';
import { getCurrentUser } from 'modules/app/selectors';
import { useAppSelector } from 'modules/store';
import './style.css';

const Navbar = () => {
  const user = useAppSelector(s => getCurrentUser(s));
  const accountLink = user ? "/account" : `${AUTH_URL}/?return_to=${encodeURIComponent(window.location.href)}&product=miso-dashboard`;
  const accountIcon = user ? <Person /> : <Login />;
  const accountText = user ? "Account" : "Login";

  return (
    <Stack
      direction="row"
      className="navbar-stack"
      justifyContent='space-between'
      alignContent='center'
      alignItems='center'
      p={1}>
      <a href="https://www.misoenergy.org/" target="_blank" style={{marginRight: "91px"}}>
        <img src='https://singularity-public-assets.s3.amazonaws.com/miso/misoLogoWhite.svg'
            alt="" width="100" className="d-inline-block align-text-top"/>
      </a>
      <Box className="navbar-link--container tour--new-navbar">
        <NavLink to="/historical" className="navbar--link">Historical</NavLink>
        <NavLink to="/realtime" className="navbar--link">Near Real-time</NavLink>
        <NavLink to="/marginal" className="navbar--link">Marginal</NavLink>
        <NavLink to="/projections" className="navbar--link">Projections</NavLink>
        <NavLink to="/consumed" className="navbar--link">Consumed</NavLink>
      </Box>
      <div className="navbar-right">
        <Button 
          component={Link}
          to={accountLink}
          variant="outlined"
          startIcon={accountIcon}
          sx={{background: "transparent", color: "white", marginRight: "20px"}}
        >{accountText}</Button>
        <a href="https://singularity.energy" target="_blank">
          <img src='https://singularity-public-assets.s3.amazonaws.com/logo/powered_by_3_white.svg'
                alt="" width="100" className="d-inline-block align-text-top"/>
        </a>
      </div>
    </Stack>
  );
}

export default Navbar;
