import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Navbar from "components/GenericNavbar";
import { AUTH_URL } from "constants/constants";
import { getCurrentCustomer, getCurrentUser, hasReceivedAuth } from "modules/app/selectors";
import { useAppSelector } from "modules/store";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link as ReactLink } from 'react-router-dom';
import "./style.css";

const AccountPage = () => {
  const receivedAuth = useAppSelector(hasReceivedAuth);
  const currentUser = useAppSelector(getCurrentUser);
  const currentCustomer = useAppSelector(getCurrentCustomer);

  useEffect(() => {
    if (receivedAuth && !currentUser) {
      window.location.href = `${AUTH_URL}/?return_to=${encodeURIComponent(window.location.href)}&product=miso-dashboard`;
    }
  }, [receivedAuth, currentUser]);

  const [copied, setCopied] = useState(false);

  const copyApiKey = () => {
    navigator.clipboard.writeText(currentCustomer?.api_key_value);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  return <div className="App">
    <Container maxWidth={false} disableGutters={true}>
      <Navbar />
      <Stack direction="row" margin="35px" spacing={12} justifyContent="center">
        <Box display="flex">
          <Card elevation={6}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold" gutterBottom>Account Info</Typography>
              <Typography gutterBottom>Email: {currentUser?.email}</Typography>
              <Stack direction="row" spacing={2} marginTop="40px" justifyContent="center">
                <Button 
                  component={ReactLink} 
                  to={`${AUTH_URL}/logout?return_to=${encodeURIComponent(window.location.href)}&product=miso-dashboard`}
                  variant="contained"
                  disableElevation
                  sx={{
                    backgroundColor: "var(--color-blue-1)",
                    color: "white",
                    fontSize: "18px",
                    "&:hover": {
                      backgroundColor: "#004d71",
                      color: "white"
                    }
                  }}
                >
                  Logout
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Box>
        <Box display="flex">
          <Card elevation={6}>
            <CardContent>
              <Typography variant="h5" fontWeight="bold">API Key</Typography>
              <Typography marginBottom="15px">Read the API documentation <Link href="/api-docs">here</Link>.</Typography>
              <Typography fontSize="12px" color="var(--color-gray)">Click to copy</Typography>
              <Card variant="outlined" onClick={copyApiKey} sx={{backgroundColor: "var(--color-light-gray)"}}>
                <CardActionArea sx={{"&:hover": {backgroundColor: "action.selectedHover"}}}>
                  <CardContent>
                    <Typography fontFamily="monospace">{currentCustomer?.api_key_value}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Typography fontSize="12px" className={`api-key-copied${copied ? " visible" : ""}`}>Copied to clipboard!</Typography>
            </CardContent>
          </Card>
        </Box>
      </Stack>
    </Container>
  </div>
}

export default AccountPage;