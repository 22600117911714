import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';

import DataView from 'components/DataView';
import GenericNavbar from 'components/GenericNavbar';
import JoyrideTour from 'components/JoyrideTour';
import MapView from 'components/MapView';
import OptionsMenu from 'components/OptionsToolbar';
import SettingsModal from 'components/SettingsModal';
import { Duration, EmissionFramework, MapLevel } from 'constants/enums';
import { currentAppState } from 'modules/app/selectors';
import { HISTORICAL_END, HISTORICAL_START, changeMapLevel, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';


const DashboardLayout = () => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(currentAppState);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  useEffect(() => {
    dispatch(changeMapLevel({level: MapLevel.LRZs}))
    dispatch(updateAppState({
      queryStartDate: HISTORICAL_START,
      queryEndDate: HISTORICAL_END,
      timeResolution: Duration.Year,
      emissionFramework: EmissionFramework.Generated
    }));
  }, []);

  return (
    <div className="App">
      <Container maxWidth={false} disableGutters={true}>
        <JoyrideTour/>

        <SettingsModal
          open={settingsMenuOpen}
          setOpen={setSettingsMenuOpen}
        />

        <GenericNavbar/>

        <OptionsMenu
          toggleSettingsMenu={() => setSettingsMenuOpen(!settingsMenuOpen)}
        />

        <Grid container>
          <Grid item xs={12} lg={5} order={{ xs: 2, lg: 1 }}>
            <DataView/>
          </Grid>
          <Grid item xs={12} lg={7} order={{ xs: 1, lg: 2 }}>
            <MapView showOnlyMISO showLevelSelect={false} showZoomButton={![Duration.Hour, Duration.Day].includes(appState.timeResolution)} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}


export default DashboardLayout;
