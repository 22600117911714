import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import React, { useRef, useState } from 'react';
import Select from 'react-select';

import * as Constants from 'constants/constants';
import {
  Duration,
} from 'constants/enums';
import { partial } from 'lodash';
import { currentAppState } from 'modules/app/selectors';
import { HISTORICAL_END, IAppSlice, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { useLocation } from 'react-router-dom';
import { singularitySelectStyle } from 'utils/ReactSelectStyle';

dayjs.extend(minMax);


const DATE_CMP_FORMAT = 'YYYY-MM-DD';

interface IDateOption {
  value: Dayjs,
  label: string
}


function getYearRange(minYear: number, maxYear: number): number[] {
  if (minYear > maxYear) {
    return [];
  }
  let range = [];
  for (let y = minYear; y <= maxYear; ++y) {
    range.push(y);
  }
  return range;
}


function getYearOptions(minYear: number, maxYear: number, startOf: boolean): IDateOption[] {
  return getYearRange(minYear, maxYear).map((y: number) => {
    // const d = dayjs.tz(`${y.toString()}-06-01T00:00:00`, timezone);
    // NOTE(milo): Only works on Safari if the `T` is included!
    const d = dayjs.utc(`${y}-01-01T00:00:00+00:00`);
    return {
      // value: startOf ? d.startOf('year') : d.endOf('year'),
      value: startOf ? d.startOf('year') : d.endOf('year'),
      label: y.toString()
    }
  });
}


function getDayOptions(minDay: Dayjs, maxDay: Dayjs): IDateOption[] {
  if (maxDay <= minDay) {
    return [];
  }
  let range = [];
  let day = minDay.utc();
  while (day <= maxDay) {
    range.push({ value: day, label: day.utc().format('MMM D, YYYY') });
    day = day.add(1, 'day');
  }
  return range;
}


export const YearPicker = () => {
  const appState = useAppSelector(currentAppState);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isProjectionsPage = location.pathname.endsWith('/projections');

  let minYear = isProjectionsPage ? HISTORICAL_END.year() : Constants.EARLIEST_DATA_YEAR;
  let maxYear = Constants.LATEST_DATA_YEAR;
  let startDateLabel = 'Start Year';
  let endDateLabel = 'End Year';
  let startDateOptions: IDateOption[] = [];
  let endDateOptions = [];

  if (isProjectionsPage) {
    maxYear = Constants.LATEST_PROJECTIONS_YEAR;
  }

  // For daily or smaller resolution, we let the user filter by date.
  if ([Duration.Five_Minute, Duration.Hour, Duration.Day].includes(appState.timeResolution)) {
    const minDate = dayjs.utc('2019-01-01T00:00:00+00:00').startOf('day');
    const maxDate = dayjs.utc(`${Constants.LATEST_DATA_YEAR}-12-31T00:00:00+00:00`).endOf('day');
    startDateOptions = getDayOptions(
      minDate, maxDate);
    endDateOptions = getDayOptions(
      appState.queryStartDate ? appState.queryStartDate : minDate, maxDate);
    startDateLabel = 'Start Day';
    endDateLabel = 'End Day';
    // For monthly, yearly, and sub-year data, the user filters by year.
  } else {
    startDateOptions = getYearOptions(
      minYear, appState.queryEndDate ? appState.queryEndDate.year() : maxYear, true);
    endDateOptions = getYearOptions(
      appState.queryStartDate ? appState.queryStartDate.year() : minYear, maxYear, false);
  }

  return (
    <>
      <Grid item xs={6} md={2} lg={2} sx={{ display: "flex", flexDirection: "column" }}>
        <label className="form-label--toolbar">{startDateLabel}</label>
        <Select
          className='react-select-toolbar'
          options={startDateOptions}
          onChange={(value: IDateOption, _) => {
            let partialUpdate: Partial<IAppSlice> = { queryStartDate: value.value };
            if (appState.queryEndDate && partialUpdate.queryStartDate > appState.queryEndDate) {
              if ([Duration.Five_Minute, Duration.Hour, Duration.Day].includes(appState.timeResolution)) {
                partialUpdate.queryEndDate = partialUpdate.queryStartDate.add(14, 'days');
              } else if (appState.timeResolution === Duration.Month) {
                partialUpdate.queryEndDate = partialUpdate.queryStartDate.add(6, 'months');
              } else if (appState.timeResolution === Duration.Year) {
                partialUpdate.queryEndDate = partialUpdate.queryStartDate.add(3, 'years');
              }
            }
            // NOTE(milo): Hacky way to let the use change their hourly window without being blocked by the data limit.
            if (appState.queryEndDate &&
              appState.timeResolution === Duration.Hour &&
              Math.abs(partialUpdate.queryStartDate.diff(appState.queryEndDate, 'days')) >= Constants.MAX_DAYS_OF_HOURLY_DATA) {
              partialUpdate.queryEndDate = partialUpdate.queryStartDate.add(Constants.MAX_DAYS_OF_HOURLY_DATA - 1, 'day');
            }
            if (appState.queryEndDate &&
              appState.timeResolution === Duration.Day &&
              Math.abs(partialUpdate.queryStartDate.diff(appState.queryEndDate, 'months')) >= Constants.MAX_MONTHS_OF_DAILY_DATA) {
              partialUpdate.queryEndDate = partialUpdate.queryStartDate.add(Constants.MAX_MONTHS_OF_DAILY_DATA - 1, 'month');
            }
            dispatch(updateAppState(partialUpdate));
          }}
          value={startDateOptions.find(el => (el.value.utc().format(DATE_CMP_FORMAT) === appState.queryStartDate.utc().format(DATE_CMP_FORMAT)))}
          styles={singularitySelectStyle}
        />
      </Grid>
      <Grid item xs={6} md={2} lg={2} sx={{ display: "flex", flexDirection: "column" }} className="projections-tour--end-date">
        <label className="form-label--toolbar">{endDateLabel}</label>
        <Select
          className='react-select-toolbar'
          options={endDateOptions}
          onChange={(value: IDateOption, _) => {
            dispatch(updateAppState({ queryEndDate: value.value }));
          }}
          value={endDateOptions.find(el => (el.value.utc().format(DATE_CMP_FORMAT) === appState.queryEndDate.utc().format(DATE_CMP_FORMAT)))}
          styles={singularitySelectStyle}
        />
      </Grid>
    </>
  )
};


export const SingleDatePicker = ({ showTodayButton = true, maxDate }: { showTodayButton?: boolean, maxDate: Dayjs }) => {
  const appState = useAppSelector(currentAppState);
  const [isOpen, setIsOpen] = useState(false);
  const customInputRef = useRef();
  const dispatch = useAppDispatch();

  const onChangeHandler = (newDate: Dayjs | null) => {
    if (newDate) {
      dispatch(updateAppState({
        queryStartDate: newDate.startOf('day'),
        queryEndDate: newDate.endOf('day'),
      }))
    }
  };

  const setToToday = () => {
    dispatch(updateAppState({
      queryStartDate: dayjs().startOf('day'),
      queryEndDate: dayjs().endOf('day'),
    }));
  };

  return <div className="realtime-tour-date-picker">
    <DatePicker
      onChange={onChangeHandler}
      onClose={() => { setIsOpen(false); }}
      open={isOpen}
      minDate={dayjs().startOf('day').subtract(2, 'year')}
      maxDate={maxDate}
      PopperProps={{ anchorEl: customInputRef.current }}
      renderInput={({
        ref,
      }) => (
        <div ref={ref} className="options-toolbar-date-picker--container">
          <label>Date</label>
          <div
            ref={customInputRef}
            className="options-toolbar-date-picker--value"
            onClick={() => setIsOpen(!isOpen)}
          >{appState.queryStartDate.toDate().toDateString()}</div>
        </div>)
      }
      value={appState.queryStartDate}
    />
    {showTodayButton && <Button variant='outlined' className="options-toolbar-date-picker--today" disabled={dayjs().startOf('day').valueOf() <= appState.queryStartDate.valueOf()} onClick={setToToday}>Today</Button>}
  </div>
};

export const StartEndDatePicker = ({ minDate, maxDate }: { minDate: Dayjs, maxDate: Dayjs }) => {
  const appState = useAppSelector(currentAppState);
  const [startIsOpen, setStartIsOpen] = useState(false);
  const [endIsOpen, setEndIsOpen] = useState(false);
  const customInputRefStart = useRef();
  const customInputRefEnd = useRef();
  const dispatch = useAppDispatch();

  const onChangeHandler = (property: "queryStartDate" | "queryEndDate", newDate: Dayjs | null) => {
    if (newDate) {
      const action = {
        [property]: property === "queryStartDate" ? newDate.startOf('day') : newDate.endOf('day'),
      };
      if (property === "queryStartDate") {
        if (newDate > appState.queryEndDate) {
          action["queryEndDate"] = newDate.endOf('day');
        } else if (appState.timeResolution === Duration.Hour) {
          action["queryEndDate"] = dayjs.min(newDate.add(Constants.MAX_DAYS_OF_HOURLY_DATA - 1, "day"), appState.queryEndDate)
        }
      }
      dispatch(updateAppState(action));
    }
  };

  let max = maxDate;
  if (appState.timeResolution === Duration.Hour) {
    max = dayjs.min(maxDate, appState.queryStartDate.add(Constants.MAX_DAYS_OF_HOURLY_DATA - 1, "day"));
  }

  return <div className="realtime-tour-date-picker">
    <DatePicker
      onChange={partial(onChangeHandler, "queryStartDate")}
      onClose={() => { setStartIsOpen(false); }}
      open={startIsOpen}
      minDate={minDate}
      maxDate={maxDate}
      PopperProps={{ anchorEl: customInputRefStart.current }}
      renderInput={({
        ref,
      }) => (
        <div ref={ref} className="options-toolbar-date-picker--container">
          <label>Start Date</label>
          <div
            ref={customInputRefStart}
            className="options-toolbar-date-picker--value"
            onClick={() => setStartIsOpen(!startIsOpen)}
          >{appState.queryStartDate.toDate().toDateString()}</div>
        </div>)
      }
      value={appState.queryStartDate}
    />
    <DatePicker
      onChange={partial(onChangeHandler, "queryEndDate")}
      onClose={() => { setEndIsOpen(false); }}
      open={endIsOpen}
      minDate={appState.queryStartDate}
      maxDate={max}
      PopperProps={{ anchorEl: customInputRefEnd.current }}
      renderInput={({
        ref,
      }) => (
        <div ref={ref} className="options-toolbar-date-picker--container">
          <label>End Date</label>
          <div
            ref={customInputRefEnd}
            className="options-toolbar-date-picker--value"
            onClick={() => setEndIsOpen(!endIsOpen)}
          >{appState.queryEndDate.toDate().toDateString()}</div>
        </div>)
      }
      value={appState.queryEndDate}
    />
  </div>
}