import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import * as React from 'react';

import { IGetRealtimeDataResponse, useGetRealtimeDataQuery } from 'api/data';
import RealTimeDataView from 'components/DataView/RealTime';
import Navbar from 'components/GenericNavbar';
import RealtimeJoyrideTour from 'components/JoyrideTour/realtime';
import MapView from 'components/MapView';
import OptionsToolbar from 'components/OptionsToolbar';
import SettingsModal from 'components/SettingsModal';
import { Duration, EmissionFramework, MapLevel } from 'constants/enums';
import dayjs from 'dayjs';
import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { IMapState, editMapSelection, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { useEffect } from 'react';
import { last } from 'utils/utils';


const mapToAPIRegions: Record<string, string> = {
  'ISNE': 'ISONE',
  'NYIS': 'NYISO',
  'BPAT': 'BPA',
  'CISO': 'CAISO',
  'ERCO': 'ERCOT',
  'SWPP': 'SPP',
}

const regionToTz: Record<string, string> = {
  'ISNE': 'US/Eastern',
  'NYIS': 'US/Eastern',
  'BPAT': 'US/Pacific',
  'CISO': 'US/Pacific',
  'ERCO': 'US/Central',
  'SWPP': 'US/Central',
  'PJM': 'US/Eastern',
  'MISO': 'EST',
}

const getMostRecentDataTimestamp = (data: IGetRealtimeDataResponse) => {
  let latestData = 0;
  data?.fuelMix.forEach(d => {
    if (!latestData) {
      latestData = new Date(d.startDate).valueOf();
    } else {
      const newEpoch = new Date(d.startDate).valueOf();
      if (newEpoch > latestData) {
        latestData = newEpoch;
      }
    }
  });
  return latestData;
};


const RealtimePage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(editMapSelection({editMode: "overwrite", mapLevel: MapLevel.ISOs, mapSelection: new Set(["MISO"])}));
    dispatch(updateAppState({
      queryStartDate: dayjs().startOf('day'),
      queryEndDate: dayjs().endOf('day'),
      emissionFramework: EmissionFramework.Generated,
      timeResolution: Duration.Five_Minute
    }));
  }, [dispatch]);

  const [settingsMenuOpen, setSettingsMenuOpen] = React.useState(false);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last<IMapState | undefined>(mapHistory);
  const appState = useAppSelector(currentAppState);
  const region = [...mapState.mapSelection] && [...mapState.mapSelection][0];
  const start = appState.queryStartDate;
  const end = appState.queryEndDate;
  const dateRangeTooBig = start.diff(end, 'day') > 1;
  const source = appState.regionSource;
  const resolution = appState.timeResolution;
  const factorAdjustment = appState.emissionAdjustment;

  const {
    data,
    isLoading,
    isFetching,
  } = useGetRealtimeDataQuery({
    region: mapToAPIRegions[region] || region,
    start: start.tz(regionToTz[region], true).startOf('day').toDate().toISOString(),
    end: start.tz(regionToTz[region], true).endOf('day').toDate().toISOString(),
    source,
    resolution,
    factorAdjustment,
  }, {skip: !mapState || mapState.mapSelection.size === 0 || dateRangeTooBig});

  return <div className="App">
    <Container maxWidth={false} disableGutters={true}>
      <Navbar />

      <RealtimeJoyrideTour />

      <SettingsModal
        open={settingsMenuOpen}
        setOpen={setSettingsMenuOpen}
      />

      <OptionsToolbar
        toggleSettingsMenu={() => setSettingsMenuOpen(!settingsMenuOpen)}
        datePickerType='date'
        leftMostSelection='regionSource'
        latestRealtimeRequestFinished={getMostRecentDataTimestamp(data)}
      />

      <Grid container>
        <Grid item xs={12} lg={7} order={{ xs: 2, lg: 1 }}>
          <RealTimeDataView data={data} isLoading={isLoading || isFetching} noData={!mapState || mapState.mapSelection.size === 0}/>
        </Grid>
        <Grid item xs={12} lg={5} order={{ xs: 1, lg: 2 }}>
          <MapView
            showOnlyMISO={true}
            showLevelSelect={false}
            showRegionSelect={false}
            showZoomButton={false}
            allowMultipleRegions={false}
            allowedMapLevels={source === 'ISO' ? [MapLevel.ISOs] : [MapLevel.BAs, MapLevel.ISOs, MapLevel.LARGE_BAs]}
            showPlants={false}
          />
        </Grid>
      </Grid>
    </Container>
  </div>
}

export default RealtimePage;