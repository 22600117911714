import CloudOffRounded from '@mui/icons-material/CloudOffRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import * as Constants from 'constants/constants';
import dayjs from 'dayjs';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { flatten, keyBy, property, snakeCase } from 'lodash';
import React, { useEffect } from 'react';

import { IGetMarginalDataResponse } from 'api/data';
import { EmissionAdjustment, MapLevel, Units } from 'constants/enums';
import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { IMapState, changeMapLevel, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { fuelCategoryPalette } from 'utils/ColorPalette';
import { arraySum, durationToDuration, getBrowserWidth, last } from 'utils/utils';

import { HelpPopup } from 'components/HelpPopup';
import { getDoesRegionExistInLevel } from 'components/MapView/utils';
import './style.css';
import { createDownloadFunction } from './utils';


const regionToTz: Record<string, string> = {
  'ISNE': 'US/Eastern',
  'NYIS': 'US/Eastern',
  'BPAT': 'US/Pacific',
  'CISO': 'US/Pacific',
  'ERCO': 'US/Central',
  'SWPP': 'US/Central',
  'PJM': 'US/Eastern',
  'MISO': 'EST',
}


const makeChartOptions = (
  series: {data: {x: number, y: number}[], zIndex?: number, name: string, color?: string, type?: string, yAxis?: number}[],
  chartType: string,
  title: string = '',
  yAxisTitle: string = '',
  extraPlotOptions: any = {},
  secondaryYAxisTitle: string = '',
  region: string = '',
  yAxisFormatter?: ({value}: {value: number}) => string
) => {
  const startOfToday = new Date();
  startOfToday.setUTCHours(0, 0, 0, 0);
  const endOfToday = dayjs().tz(regionToTz[region], true).endOf('day').toDate();
  const minX = Math.min(...flatten(series.map(({data}) => data.map(({x}) => x))));
  const numAfterTodayStart = flatten(series.map(d => d.data.filter(d1 => d1.x > startOfToday.valueOf()))).length;
  const numTotalData = flatten(series.map(d => d.data)).length;
  // not the best way to determine this, but it works
  const isTodayData = numAfterTodayStart > (numTotalData/2);
  const maxX = Math.max(...flatten(series.map(({data}) => data.map(({x}) => x))));
  const yAxes: any = [{title: {text: yAxisTitle, useHTML: true}, min: 0, softMax: 100, labels: {formatter: yAxisFormatter}}];
  if (secondaryYAxisTitle) {
    yAxes.push({
      title: {text: secondaryYAxisTitle, useHTML: true},
      opposite: true,
      softMin: 0,
    });
  }
  return {
    chart: {
      type: chartType,
    },
    time: {
      // Positive offset means WEST of GMT, negative means EAST.
      timezoneOffset: dayjs().tz(regionToTz[region], true).utcOffset() * -1,
    },
    title: {
      text: title,
    },
    series,
    plotOptions: {
      ...extraPlotOptions,
    },
    tooltip: {
      shared: true,
      valueDecimals: chartType === 'area' ? 2 : undefined,
    },
    xAxis: {
      title: {
        text: ''
      },
      type: 'datetime',
      minPadding: 0.05,
      maxPadding: 0.05,
      max: isTodayData ? endOfToday.valueOf() : maxX,
      min: minX,
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        y: 25,
      },
      tickInterval: 3600 * 1000,
      plotLines: [{
        color: 'var(--color-blue-2)',
        label: {
          text: 'Now',
        },
        dashStyle: 'ShortDash',
        value: Date.now()
      }],
    },
    credits: {enabled: false},
    yAxis: yAxes,
    exporting: {
      enabled: true,
      scale: 4,
      buttons: {
        contextButton: {
          symbol: "menuball",
          menuItems: ["viewFullscreen", "separator", "downloadCSV", "downloadPNG", "downloadJPEG", "downloadSVG"],
        },
      },
      menuItemDefinitions: {
        downloadCSV: {
          onclick: function () {
            document.getElementById(Constants.DOWNLOAD_BUTTON_ID).click();
          },
          text: 'Download CSV'
        }
      },
      chartOptions: {
        navigator: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        chart: {
          style: {
            fontFamily: Constants.FONT_FAMILY,
          },
        }
      },
      fallbackToExportServer: false
    }
  }
};


const makeFuelMixChartOptions = (data: IGetMarginalDataResponse, region: string) => {
  const seriesData: Record<string, {x: number, y: number}[]> = {};

  data.marginalFuelMix.forEach(mfm => {
    // split the series of one fuel mix per timestamp into
    // each fuel as a time series with a timestamp
    Object.entries(mfm.data).forEach(([fuelMw, mw]) => {
      const fuelName = snakeCase(fuelMw).replace('_mw', '');
      if (!seriesData[fuelName]) {
        seriesData[fuelName] = [];
      }
      seriesData[fuelName].push({y: mw * 100, x: new Date(mfm.startDate).valueOf()});
    })
  });

  Object.entries(seriesData).forEach(([fuel, series]) => {
    seriesData[fuel] = series.sort(({x: x1}, {x: x2}) => x1 - x2);
  });

  const series = Object
    .entries(seriesData)
    // MISO specifically asked us to not show oil or wood, since they don't report it but it's in our data
    .filter(([fuelName, _]) => !['oil', 'wood'].includes(fuelName) || region !== 'MISO')
    .map(([fuelName, fuelData]) => ({name: fuelName.replaceAll('_', ' '), color: fuelCategoryPalette[fuelName], data: fuelData, tooltip: {valueSuffix: '%', shared: true}}))
    .sort((d1, d2) => arraySum(d1.data.map(({y}) => y)) - arraySum(d2.data.map(({y}) => y)));

  return makeChartOptions(series, 'area', '', 'Marginal percentage', {
    series: {
      stacking: 'percent',
      dataGrouping: {
        enabled: false
      },
    },
    area: {marker: {enabled: false}, step: 'left'},
  }, undefined, region);
}

const makeEmissionsChartOptions = (data: IGetMarginalDataResponse, region: string, isImperialUnits: boolean) => {
  const marginalData = data.marginalCarbonIntensity
    .map(ci => {
      return {y: Math.round(isImperialUnits ? ci.data.marginalRateLbPerMwh : ci.data.marginalRateKgPerMwh), x: new Date(ci.startDate).valueOf()};
    })
    .sort(({x: x1}, {x: x2}) => x1 - x2);

  return makeChartOptions(
    [
      {name: 'Marginal Carbon Intensity', data: marginalData, color: 'red', type: 'spline', zIndex: 1},
    ],
    'areaspline',
    '',
    `intensity - CO<sub>2</sub> ${isImperialUnits ? 'lbs' : 'kg'}/MWh`,
    {areaspline: {marker: {enabled: false}}, spline: {marker: {enabled: false}}},
    undefined,
    region
  );
}

const dataIsEmpty = (data: IGetMarginalDataResponse) => {
  return data.marginalFuelMix.length === 0;
}


const NoDataWarning = () => {
  const dispatch = useAppDispatch();

  const useYesterday = () => {
    dispatch(updateAppState({
      queryStartDate: dayjs().startOf('day').subtract(1, 'day'),
      queryEndDate: dayjs().endOf('day').subtract(1, 'day'),
    }));
  }

  return (
    <div className="realtime-empty-state--container">
      <div className="realtime-empty-state-content--container">
        <div className="realtime-empty-state-icon--container"><CloudOffRounded className="realtime-empty-state--icon" /></div>
        <div className="realtime-empty-state-explanation--container">
          <h3 className="realtime-empty-state-explanation--header">We don't appear to have data!</h3>
          <p className="realtime-empty-state-explanation--text">Apologies, but we don't appear to have data for the selected day. Perhaps try yesterday's data.</p>
          <Button variant='contained' onClick={useYesterday} className="realtime-empty-state-explanation--cta">Use Yesterday's Data</Button>
        </div>
      </div>
    </div>
  )
};


const MarginalDataView = ({
  data,
  isLoading,
  noData,
}: {data: IGetMarginalDataResponse, isLoading: boolean, noData: boolean}) => {
  const appState = useAppSelector(currentAppState);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last<IMapState | undefined>(mapHistory);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const start = appState.queryStartDate;
    const end = appState.queryEndDate;

    if (end.diff(start, 'day') > 1) {
      dispatch(updateAppState({
        queryStartDate: dayjs().subtract(1, 'day').startOf('day'),
        queryEndDate: dayjs().subtract(1, 'day').endOf('day'),
      }));
    }

    if (appState.regionSource === 'ISO' && mapState?.mapLevel !== MapLevel.ISOs) {
      const firstSelectedRegion = [...(mapState?.mapSelection || [])][0];
      const setRegionTo = !firstSelectedRegion || !getDoesRegionExistInLevel(firstSelectedRegion, MapLevel.ISOs) ? 'MISO' : firstSelectedRegion;
      if (mapState?.mapSelection.size === 0) {
        dispatch(changeMapLevel({level: MapLevel.ISOs, selectedRegion: setRegionTo}));
      } else {
        dispatch(changeMapLevel({level: MapLevel.ISOs, selectedRegion: setRegionTo}));
      }
    }

    if (appState.emissionAdjustment === EmissionAdjustment.Unadjusted || appState.emissionAdjustment === EmissionAdjustment.Adjusted) {
      dispatch(updateAppState({emissionAdjustment: EmissionAdjustment.ForElectricity}));
    }
  }, [appState.queryEndDate, appState.emissionAdjustment, appState.queryStartDate, dispatch, mapState, appState.regionSource]);

  const rows: (string | number)[][] = [];
  if (!isLoading && data && data.marginalFuelMix.length > 0) {
    const fuels = new Set(data.marginalFuelMix.flatMap(mix => Object.keys(mix.data)).sort());
    rows.push([
      'start_date_utc',
      `total_${appState.pollutant.toLowerCase()}_marginal_intensity_lbs_per_mwh`
    ]);
    fuels.forEach(fuel => {
      rows[0].push(
        `${snakeCase(fuel)}_marginal_proportion`,
      );
    });

    const mfmByStartDate = keyBy(data.marginalFuelMix, property("startDate"));
    const ciByStartDate = keyBy(data.marginalCarbonIntensity, property("startDate"));
    const duration = durationToDuration(appState.timeResolution);
    let interval = dayjs(data.marginalFuelMix[0].startDate);
    Object.entries(mfmByStartDate).forEach(([startDate, mfm]) => {
      while (dayjs(startDate) > interval) {
        rows.push([interval.utc().format()]);
        interval = interval.add(duration);
      }
      const ci = ciByStartDate[startDate];
      const row = [startDate, +ci.data.marginalRateLbPerMwh.toFixed(2)];
      fuels.forEach(fuel => {
        row.push(+((mfm.data as any)[fuel] || 0).toFixed(2));
      });
      rows.push(row);
      interval = interval.add(duration);
    });
  }

  useEffect(() => {
    const button = document.getElementById(Constants.DOWNLOAD_BUTTON_ID);
    if (button) {
      button.onclick = createDownloadFunction(appState, mapState, rows);
    }
  });

  const dataPanelStyle = {
    overflow: 'visible'
  };
  if (getBrowserWidth() >= 1200) {
    dataPanelStyle['overflow'] = 'scroll';
  }

  if (noData) {
    return <div className="realtime-data-view--empty">Click on a region in the map to see real time data</div>
  }

  if (isLoading || !data) {
    return <div className="realtime-data-view--loading">
        <div className="realtime-data-view-loading--text">Loading...</div>
        <LinearProgress />
        {appState.timeResolution === '5m' && <div className="realtime-data-view-loading--help">Searching through over a billion data points –– this might take a second.</div>}
      </div>
  }

  const isImperialUnits = appState.units === Units.Imperial

  if (dataIsEmpty(data)) {
    return <NoDataWarning />;
  }

  return (
    <Box sx={dataPanelStyle} className="data-view-container">
      <Box mt={2} className="realtime-tour-fuelmix-chart">
        <label className="realtime-chart--title">Marginal Fuel Mix Percentage
          <HelpPopup popupContent={
            <>
              Each fuel's part on the margin is shown here in Eastern Standard Time. The y-axis units are in percent of fuels that make up the margin.
            </>}
          />
        </label>
        <HighchartsReact highcharts={Highcharts} options={makeFuelMixChartOptions(data, [...mapState.mapSelection][0])} />
      </Box>
      <Box mt={2} className="realtime-tour-emissions-chart">
        <label className="realtime-chart--title">Marginal Carbon Intensity
          <HelpPopup popupContent={
            <>
              Times are shown in Eastern Standard Time. The red line shows the rate of emissions for marginal grid consumption based on the fuels on the margin.
            </>}
          />
        </label>
        <HighchartsReact highcharts={Highcharts} options={makeEmissionsChartOptions(data, [...mapState.mapSelection][0], isImperialUnits)} />
      </Box>
    </Box>
  );
}


export default MarginalDataView;
