import { RootState } from 'modules/store';

const root = (state: RootState) => state.app;

export const currentAppState = (state: any) => root(state);
export const currentMapHistory = (state: any) => root(state).mapHistory;
export const currentMapHistoryLength = (state: any) => root(state).mapHistory.length;


export enum FeatureGate {
  MARCH_2025_LAUNCH = 'has_miso_march_2025_features'
}

export const hasReceivedAuth = (state: RootState) => root(state).receivedAuth;
export const getCurrentUser = (state: RootState) => root(state).currentUser;
export const getCurrentCustomer = (state: RootState) => root(state).currentCustomer;
const getAllAttributes = (state: RootState) => (getCurrentUser(state)?.attributes || []).concat(getCurrentCustomer(state)?.attributes || []);
const getGates = (state: RootState) => getAllAttributes(state).filter(attr => attr.namespace === 'GATES');

export const hasGateEnabled = (state: RootState, featureGate: FeatureGate) => getGates(state).find(gate => gate.name === featureGate)?.value === true || false;