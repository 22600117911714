import CloudOffRounded from '@mui/icons-material/CloudOffRounded';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { HelpPopup } from 'components/HelpPopup';
import * as Constants from 'constants/constants';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { flatten, snakeCase } from 'lodash';
import React, { useEffect } from 'react';

import { IConsumedDatum, IGetConsumedDataResponse } from 'api/data';
import { Units } from 'constants/enums';
import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { IMapState, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { fuelCategoryPalette } from 'utils/ColorPalette';
import { durationToDuration, getBrowserWidth, last } from 'utils/utils';

import './style.css';
import { createDownloadFunction } from './utils';

dayjs.extend(dayjsDuration);


const makeChartOptions = (
  series: {data: {x: number, y: number}[], zIndex?: number, name: string, color?: string, type?: string, yAxis?: number}[],
  chartType: string,
  title: string = '',
  yAxisTitle: string = '',
  extraPlotOptions: any = {},
  secondaryYAxisTitle: string = '',
  yAxisFormatter?: ({value}: {value: number}) => string,
  percentageYAxis: boolean = false
) => {
  const startOfToday = new Date();
  startOfToday.setUTCHours(0, 0, 0, 0);
  const endOfToday = dayjs().tz('EST', true).endOf('day').toDate();
  const minX = Math.min(...flatten(series.map(({data}) => data.map(({x}) => x))));
  const numAfterTodayStart = flatten(series.map(d => d.data.filter(d1 => d1.x > startOfToday.valueOf()))).length;
  const numTotalData = flatten(series.map(d => d.data)).length;
  // not the best way to determine this, but it works
  const isTodayData = numAfterTodayStart > (numTotalData/2);
  const maxX = Math.max(...flatten(series.map(({data}) => data.map(({x}) => x))));
  const yAxes: any = [{
    title: {text: yAxisTitle, useHTML: true},
    softMin: 0,
    max: percentageYAxis ? 100 : undefined,
    labels: {formatter: yAxisFormatter}
  }];
  if (secondaryYAxisTitle) {
    yAxes.push({
      title: {text: secondaryYAxisTitle, useHTML: true},
      opposite: true,
      softMin: 0,
    });
  }
  return {
    chart: {
      type: chartType,
      spacingTop: 25
    },
    time: {
      // Positive offset means WEST of GMT, negative means EAST.
      timezoneOffset: dayjs().tz('EST', true).utcOffset() * -1,
    },
    title: {
      text: title,
    },
    series,
    plotOptions: {
      ...extraPlotOptions,
    },
    xAxis: {
      title: {
        text: ''
      },
      type: 'datetime',
      minPadding: 0.05,
      maxPadding: 0.05,
      max: isTodayData ? endOfToday.valueOf() : maxX,
      min: minX,
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        y: 25,
      },
      tickInterval: 3600 * 1000,
      plotLines: [{
        color: 'var(--color-blue-2)',
        label: {
          text: 'Now',
        },
        dashStyle: 'ShortDash',
        value: Date.now()
      }],
    },
    credits: {enabled: false},
    yAxis: yAxes,
    exporting: {
      enabled: true,
      scale: 4,
      buttons: {
        contextButton: {
          symbol: "menuball",
          menuItems: ["viewFullscreen", "separator", "downloadCSV", "downloadPNG", "downloadJPEG", "downloadSVG"],
          y: -25
        },
      },
      menuItemDefinitions: {
        downloadCSV: {
          onclick: function () {
            document.getElementById(Constants.DOWNLOAD_BUTTON_ID).click();
          },
          text: 'Download CSV'
        }
      },
      chartOptions: {
        navigator: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        chart: {
          style: {
            fontFamily: Constants.FONT_FAMILY,
          },
        }
      },
      fallbackToExportServer: false
    },
  }
};


const roundDecimals = (number: number): number => {
  const digitsBeforeDecimal = number > 1 ? Math.floor(Math.log10(number)) + 1 : 0;
  return parseFloat(number.toPrecision(digitsBeforeDecimal + 2));
}


const makeFuelMixChartOptions = (data: IGetConsumedDataResponse) => {
  const seriesData: Record<string, {x: number, y: number}[]> = {};

  data.data.forEach((datum: IConsumedDatum) => {
    Object.entries(datum.fuelMix).filter(([key, _]) => key !== 'nonCfePercentage').forEach(([key, value]) => {
      const fuel = snakeCase(key.slice(0, -1 * 'Percentage'.length));
      if (fuel in fuelCategoryPalette) {
        if (!seriesData[fuel]) {
          seriesData[fuel] = [];
        }
        seriesData[fuel].push({y: roundDecimals(value * 100), x: new Date(datum.timestamp).valueOf()});
      }
    });
  });

  const series = Object
    .entries(seriesData)
    .map(([fuelName, fuelData]) => ({name: fuelName.replaceAll('_', ' '), color: fuelCategoryPalette[fuelName], data: fuelData, tooltip: {valueSuffix: '%'}}))
    .sort((d1, d2) => {
      return Constants.FUEL_DISPLAY_ORDER.indexOf(d2.name) - Constants.FUEL_DISPLAY_ORDER.indexOf(d1.name);
    });

  return makeChartOptions(series, 'area', '', 'Consumed Fuel Mix Percentage', {
    series: {
      stacking: 'stacked',
      dataGrouping: {
        enabled: false
      },
    },
    area: {marker: {enabled: false}},
  }, undefined, ({value}: {value: number}) => `${value}%`, true);
}

const makeEmissionsChartOptions = (data: IGetConsumedDataResponse, isImperialUnits: boolean, showTotal: boolean) => {
  const unit = isImperialUnits ? 'Lb' : 'Kg';
  const consumedData = data.data
    .map(datum => ({y: datum.emissions[`rateCo2E${unit}sPerMwh`], x: new Date(datum.timestamp).valueOf()}))
    .filter(({y}) => y !== 0);
  const nonCfeData = data.data
    .map(datum => ({y: datum.emissions[`nonCfeRateCo2E${unit}sPerMwh`], x: new Date(datum.timestamp).valueOf()}))
    .filter(({y}) => y !== 0);

  return makeChartOptions(
    [
      {name: 'Consumed Carbon Intensity', data: consumedData, color: 'red', type: 'spline', zIndex: 1},
      {name: 'Non-CFE Consumed Carbon Intensity', data: nonCfeData, color: 'darkred', type: 'spline', zIndex: 2},
      ...(showTotal ? [{
        data: data.data.map(datum => ({
          y: roundDecimals(isImperialUnits ? datum.emissions.totalCo2ELbs / 2000 : datum.emissions.totalCo2EKgs / 1000),
          x: new Date(datum.timestamp).valueOf()
        })),
        name: 'Total Consumed Emissions', yAxis: 1
      }] : []),
    ],
    'areaspline',
    '',
    `intensity - CO<sub>2</sub>e ${isImperialUnits ? 'lbs' : 'kg'}/MWh`,
    {areaspline: {marker: {enabled: false}}, spline: {marker: {enabled: false}}},
    showTotal ? `emissions - ${isImperialUnits ? '' : 'metric '} tons CO<sub>2</sub>e` : undefined,
  );
}

const dataIsEmpty = (data: IGetConsumedDataResponse) => {
  return data.data.length === 0;
}


const NoDataWarning = ({error}: {error?: any}) => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector(currentAppState);

  const useDate = (date: Date) => {
    const duration = dayjs.duration(appState.queryEndDate.diff(appState.queryStartDate));
    dispatch(updateAppState({
      queryStartDate: dayjs(date).startOf('day').subtract(duration),
      queryEndDate: dayjs(date).endOf('day'),
    }));
  }

  const maxDate = error?.data?.extras?.maximumDate;
  const explanation = maxDate ?
    `Apologies, in order to preserve the confidentiality of MISO market participants, we can't show recent data for this selection. The latest available date is ${new Date(maxDate).toLocaleDateString()}.` :
    "Apologies, we don't appear to have data for the selected days or regions. Please try a different selection.";
  return (
    <div className="realtime-empty-state--container">
      <div className="realtime-empty-state-content--container">
        <div className="realtime-empty-state-icon--container"><CloudOffRounded className="realtime-empty-state--icon" /></div>
        <div className="realtime-empty-state-explanation--container">
          <h3 className="realtime-empty-state-explanation--header">No Data Available</h3>
          <p className="realtime-empty-state-explanation--text">{explanation}</p>
          {maxDate && <Button variant='contained' onClick={useDate.bind(this, maxDate)} className="realtime-empty-state-explanation--cta">See Latest Available Data</Button>}
        </div>
      </div>
    </div>
  )
};


const ConsumedDataView = ({
  data,
  isLoading,
  noData,
  error
}: {data: IGetConsumedDataResponse, isLoading: boolean, noData: boolean, error?: any}) => {
  const appState = useAppSelector(currentAppState);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last<IMapState | undefined>(mapHistory);

  const isImperialUnits = appState.units === Units.Imperial;
  const unit = isImperialUnits ? 'Lb' : 'Kg';
  const showEmissionsTotals = data?.data?.at(0)?.emissions?.hasOwnProperty(`totalCo2E${unit}s`);

  const rows: (string | number)[][] = [];
  if (!isLoading && !error && data && data.data.length > 0) {
    const fuelKeys = Object.keys(data.data[0].fuelMix).filter(x => x !== "nonCfePercentage");
    rows.push(["start_date_utc", ...(showEmissionsTotals ? [`co2e_consumed_${unit.toLowerCase()}s`] : []), `consumed_co2e_intensity_${unit.toLowerCase()}s_per_mwh`, `consumed_non_cfe_co2e_intensity_${unit.toLowerCase()}s_per_mwh`, "non_cfe_percentage", ...fuelKeys.map(snakeCase)]);
    const duration = durationToDuration(appState.timeResolution);
    let interval = dayjs(data.data[0].timestamp);
    data.data.forEach(datum => {
      while (dayjs(datum.timestamp) > interval) {
        rows.push([interval.toISOString()]);
        interval = interval.add(duration);
      }
      const {nonCfePercentage, ...fuelPercentages} = datum.fuelMix;
      const row = [datum.timestamp, ...(showEmissionsTotals ? [datum.emissions[`totalCo2E${unit}s`]?.toFixed(2) || ""] : []), datum.emissions[`rateCo2E${unit}sPerMwh`].toFixed(2), datum.emissions[`nonCfeRateCo2E${unit}sPerMwh`].toFixed(2), nonCfePercentage.toFixed(2)];
      row.push(...Object.values(fuelPercentages).map(x => x.toFixed(2)));
      rows.push(row);
      interval = interval.add(duration);
    });
  }

  useEffect(() => {
    const button = document.getElementById(Constants.DOWNLOAD_BUTTON_ID);
    if (button) {
      button.onclick = createDownloadFunction(appState, mapState, rows);
    }
  });

  const dataPanelStyle = {
    overflow: 'visible'
  };
  if (getBrowserWidth() >= 1200) {
    dataPanelStyle['overflow'] = 'scroll';
  }

  if (noData) {
    return <div className="realtime-data-view--empty">Click on a region in the map to see consumed data</div>
  }

  if (isLoading || !data) {
    return <div className="realtime-data-view--loading">
        <div className="realtime-data-view-loading--text">Loading...</div>
        <LinearProgress />
        {appState.timeResolution === '5m' && <div className="realtime-data-view-loading--help">Searching through over a billion data points –– this might take a second.</div>}
      </div>
  }

  if (error || dataIsEmpty(data)) {
    return <NoDataWarning error={error} />;
  }

  return (
    <Box sx={dataPanelStyle} className="data-view-container">
      <Alert severity="info">
        Consumed emissions reported on MISO's dashboard are "location-based" data that reflect how electricity physically flows from generators to consumers along transmission lines. They do not represent "market-based" allocations such as power purchase agreements, wholesale market purchases, utility tariffs, or RECs.
      </Alert>
      <Box mt={2} className="realtime-tour-emissions-chart">
        <label className="realtime-chart--title">{showEmissionsTotals ? "Total Consumed Emissions & " : "Consumed "}Carbon Intensity
          <HelpPopup popupContent={
            <>
              Times are shown in Eastern Standard Time.
              The lighter red line shows the rate of emissions for grid consumption based on the fuel mix.
              The darker red line shows the rate of emissions only considering the non-CFE (non carbon-free energy or fossil-only) fuel mix.
              {showEmissionsTotals ?
                ` The blue area shows the total emissions based on the fuels used for generation.`
              : ` Total emissions are not shown for this selection to preserve the confidentiality of MISO market participants.`}
            </>}
          />
        </label>
        <HighchartsReact highcharts={Highcharts} options={makeEmissionsChartOptions(data, isImperialUnits, showEmissionsTotals)} />
      </Box>
      <Box sx={{width: "92%"}} mt={2} className="realtime-tour-fuelmix-chart">
        <label className="realtime-chart--title">Consumed Fuel Mix</label>
        <HighchartsReact highcharts={Highcharts} options={makeFuelMixChartOptions(data)} />
      </Box>
    </Box>
  );
}


export default ConsumedDataView;
